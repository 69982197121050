import { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";

const METHOD_SEND_TRANSACTION = "eth_sendTransaction";
const CACHE_SIGNATURE = "WalletSign";
const CACHE_WALLET = "WalletIsConnected";

export const WalletContext = createContext();

/**
 * Constructs the data string object needed for wallet transactions.
 * It takes data from the backend that it then parses.
 * 
 * @param {array} data - List of addresses from backend.
 * @param {string} receiver - Receiver address if needed for substitution.
 * @returns One data point used in the wallet transaction.
 */
const convertToTransactionData = (data, receiver) => {
  return "0x" + data.map(row => {
    if (row === "**RECEIVER**") {
      return `000000000000000000000000${receiver.substring(2)}`;
    }

    return row.substring(2);
  }).join("");
};

/**
 * Wallet context provider for all pages and components to
 * be able to get and manipulate wallet information.
 * It relies on window.ethereum being present and on "ethers" library.
 * Configures ways to connect a wallet account, whether one is present,
 * and changing accounts.
 */
const WalletProvider = (props) => {
  const [isWalletAppInstalled, setIsWalletAppInstalled] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [provider, setProvider] = useState(null);

  const handleChangeWallet = async () => {
    if (!showWallet) {
      connectWallet();
    } else {
      setShowWallet(false);
      localStorage.setItem(CACHE_WALLET, "false");
    }
  };
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        await accountsChanged(res[0]);
        setShowWallet(true);
        setShowModal(false);
        setProvider(new ethers.providers.Web3Provider(window.ethereum));
        localStorage.setItem(CACHE_WALLET, "true");
      } catch (err) {
        localStorage.setItem(CACHE_WALLET, "false");
      }
    } else {
      setIsWalletAppInstalled(true);
      setShowModal(true);
    }
  };
  const accountsChanged = async (newAccount) => {
    setAccount(newAccount);
    setShowWallet(false);
    try {
      const balance = await window.ethereum.request({
        method: "eth_getBalance",
        params: [newAccount.toString(), "latest"],
      });
      setShowWallet(false);
      setBalance(ethers.utils.formatEther(balance));
    } catch (err) {
      setShowWallet(false);
    }
  };
  const sendRequest = async (method, params) => {
    try {
      await window.ethereum.request({
        method: method,
        params: [params],
      });

      return true;
    } catch (err) {
      setShowWallet(false);
      return false;
    }
  };

  const sendMintTransaction = async (receiver, transaction, successCallback) => {
    const data = convertToTransactionData(transaction.data, receiver);

    const mintParams = {
      from: account,
      to: transaction.to,
      value: transaction.value,
      data,
      // gasPrice: ethers.utils.hexlify(10000),
      // gasLimit: ethers.utils.hexlify(parseInt(await provider.getGasPrice())),
    };

    const isSuccessful = await sendRequest(METHOD_SEND_TRANSACTION, mintParams);
    successCallback(isSuccessful);
  };

  const sendPriceUpdateTransaction = async (receiver, transaction, successCallback) => {
    const data = convertToTransactionData(transaction.data, receiver);

    const mintParams = {
      from: account,
      to: transaction.to,
      value: transaction.value,
      data,
    };

    const isSuccessful = await sendRequest(METHOD_SEND_TRANSACTION, mintParams, transaction);
    successCallback(isSuccessful);
  };

  const sendBuyTransaction = async (receiver, transaction, successCallback) => {
    const data = transaction.data
      .map((data) => {
        if (data === "**RECEIVER**") {
          return `000000000000000000000000${receiver.substr(2)}`;
        }
        if (data === transaction.data[2]) {
          return `${transaction.data[2].substr(2)}`;
        }

        return data;
      })
      .join("");

    const buyParams = {
      from: account,
      to: transaction.to,
      value: transaction.value,
      data,
    };

    const isSuccessful = await sendRequest(METHOD_SEND_TRANSACTION, buyParams);
    successCallback(isSuccessful);
  };
  
  const getSignature = async (signature, onError) => {
    try {
      const currentTime = new Date();
      const cachedSignature = JSON.parse(localStorage.getItem(CACHE_SIGNATURE));
      if (cachedSignature && currentTime.getTime() < cachedSignature.expiry) {
        return cachedSignature;
      }

      const signer = await provider.getSigner();
      const newSignature = await signer.signMessage(signature.message);
      const newCachedSignature = {
        sign: newSignature,
        expiry: currentTime.getTime() + signature.validity * 1000,
        nonce: signature.nonce,
      };
      localStorage.setItem(CACHE_SIGNATURE, JSON.stringify(newCachedSignature));

      return newCachedSignature;
    } catch (e) {
      if (onError) {
        onError();
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountsChanged);
    
      const cachedWallet = JSON.parse(localStorage.getItem(CACHE_WALLET));
      if (cachedWallet) {
        connectWallet();
      }
    }
  }, []);

  const values = {
    isWalletAppInstalled,
    setIsWalletAppInstalled,
    account,
    setAccount,
    balance,
    setBalance,
    showModal,
    setShowModal,
    showWallet,
    setShowWallet,
    handleChangeWallet,
    connectWallet,
    accountsChanged,
    sendMintTransaction,
    sendPriceUpdateTransaction,
    getSignature,
    sendBuyTransaction,
  };

  return (
    <WalletContext.Provider value={values}>
      {props.children}
    </WalletContext.Provider>
  );
};
export default WalletProvider;
