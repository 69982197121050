import "./loader.css";

/**
 * Simple loader component that has most of its logic
 * placed in the loader.css stylesheet.
 * @component
 */
const Loader = () => {
  return <div className="loader">
    <div></div>
  </div>
};

export default Loader;
