import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./drawer.css";
import { WalletContext } from "../../context/WalletContext";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "./Button";
import ConnectWalletModal from "./ConnectWalletModal";
import menu from "../../images/menuIcon.svg";
import FooterMobile from "./FooterMobile";
import logoImage from "../../images/logo_white.png";

/**
 * Shows a panel that displays the application navigation option
 * from the left edge of the screen and occupies half of the
 * screen on mobile devices below 600 pixels
 * For devices above 600 pixels it occupies 30% of the screen
 * Combines application navigation
 * links from our header and footer components.
 */

export const Drawer = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const { showWallet, showModal, setShowModal, handleChangeWallet, account } =
    useContext(WalletContext);
  const navLinkClasses = ({ isActive }) => (isActive ? "active" : "");

  return (
    <>
      <div
        className={!open ? " overlay overlay-hidden" : " overlay overlay-open"}
        onClick={onClose}
        aria-hidden="true"
      />
      <div
        tabIndex="-1"
        className={open ? " drawer animate" : " drawer hidden"}
      >
        <div className={"header"}>
          <ConnectWalletModal show={showModal} handleClose={setShowModal} />

          <div className="logo" onClick={() => navigate("/")}>
            <img src={logoImage} alt="billionmatic" />
          </div>
          <img src={menu} alt="menu" onClick={onClose} />
        </div>
        <div className="body">
          {showWallet && (
            <p>
              <span>Hi, </span> &nbsp;
              {account.substring(0, 6)}…{account.substring(account.length - 4)}
            </p>
          )}
          <div className="body links">
            <NavLink to="/" className={navLinkClasses}>
              Home
            </NavLink>
            <NavLink to="/history" className={navLinkClasses}>
              History
            </NavLink>
            <NavLink to="/buy" className={navLinkClasses}>
              Buy
            </NavLink>
            <NavLink to="/mint" className={navLinkClasses}>
              Mint
            </NavLink>
            <NavLink to="/manage" className={navLinkClasses}>
              Manage
            </NavLink>
          </div>
          <div className="footer">
            {" "}
            {!showWallet ? (
              <Button
                onClick={() => setShowModal(true)}
                buttonText={"Connect wallet"}
              />
            ) : (
              <Button
                onClick={handleChangeWallet}
                buttonText={"Disconnect wallet"}
              />
            )}
          </div>
          <FooterMobile />
        </div>
      </div>
    </>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
