import { useContext, useEffect, useState } from "react";
import NFTApiService from "../../api/NFTApiService";
import Header from "../Layout/Header";
import Loader from "../Layout/Loader";
import Wrapper from "../Layout/Wrapper";
import NFTMap from "../NFTMap/NFTMap";
import { MAX_ZOOM_REDUCED } from "../NFTMap/ZoomOptions";
import "./mint.css";
import ConnectWalletModal from "../Layout/ConnectWalletModal";
import { WalletContext } from "../../context/WalletContext";
import SuccessModal from "../Layout/SuccessModal";
import OptionalAddress from "./OptionalAddress";
import Footer from "../Layout/Footer";

/**
 * Mint page that enables the user to mint a random NFT.
 * Communicates with the wallet to do the transaction.
 * Takes transaction info from back-end.
 *
 * @component
 */
const Mint = () => {
  const [nftData, setNftData] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [bottomDrawer, openBottomDrawer] = useState(true);

  const { account, sendMintTransaction, showWallet, showModal, setShowModal } =
    useContext(WalletContext);

  useEffect(() => {
    if (showWallet && account) {
      NFTApiService.GetMintData(account.substring(2)).then((data) => {
        setNftData(data);
      });
    } else {
      NFTApiService.GetMintData().then((data) => {
        setNftData(data);
      });
    }
  }, [showWallet, account]);

  const onMint = (optionalReceiver) => {
    if (!showWallet) {
      setShowModal(true); // Show connect modal if no wallet.
    } else {
      sendMintTransaction(
        optionalReceiver || account,
        nftData.transaction,
        setShowSuccessModal
      );
    }
  };

  return (
    <Wrapper>
      <ConnectWalletModal show={showModal} handleClose={setShowModal} />
      <SuccessModal show={showSuccessModal} handleClose={setShowSuccessModal} />
      <Header />
      {!!nftData && bottomDrawer ? (
        <div className="mint-header">
          <button
            className="bottom-drawer"
            onTouchStart={() => openBottomDrawer(!bottomDrawer)}
            onClick={() => openBottomDrawer(!bottomDrawer)}
          >
            <hr />
          </button>
          <h1>
            Mint your BMP NFT and start branding yourself or your business
          </h1>
          <h3>
            You can mint a random one of the remaining {nftData.mintCount} NFTs
            for {nftData.mintPrice} MATIC.
            {nftData.whitelistspots && (
              <span className="whitelist">
                &nbsp;(
                <span className="regular-price">
                  {nftData.regularPrice} MATIC
                </span>{" "}
                {nftData.whitelistspots} whitelist spots)
              </span>
            )}
          </h3>
          <OptionalAddress
            mintCount={nftData.mintCount}
            mintPrice={nftData.mintPrice}
            onClick={onMint}
          />
        </div>
      ) : (
        ""
      )}
      {!!nftData ? (
        <NFTMap
          mapData={nftData.mapData}
          imageParts={[nftData.img]}
          columnCount={nftData.divX}
          rowCount={nftData.divY}
          maxZoomLevel={MAX_ZOOM_REDUCED}
          onClick={() => openBottomDrawer(true)}
          isMintView={true}
          isSingleView={true}
          disableClickVerification={true}
        />
      ) : (
        <Loader />
      )}
      <Footer />
    </Wrapper>
  );
};

export default Mint;
