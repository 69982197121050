import browseNFTImage from "../../images/browseNFT.svg";
import customizeNFTImage from "../../images/customizeNFT.svg";
import trackNFTImage from "../../images/trackNFT.svg";

/**
 * Second Column component is used in How it works page.
 * It contains all the text from the right side of screen
 * on the web version.
 * @component
 */
const SecondColumn = () => {
  return (
    <div className="column second hiw-page">
      <img src={browseNFTImage} alt="browse-nft" />
      <h1>Browse the available NFTs</h1>
      <p>
        Once you have a polygon wallet, you can browse the available NFTs on the main page of the Billionmaticpage.
        Each NFT is unique and has its own set of characteristics and attributes (location, size etc.). 
      </p>
      <img src={customizeNFTImage} alt="customize-nft" />
      <h1>Customize your NFT</h1>
      <p>
        Once you have an NFT, you can customize its appearance using the built-in customization tools on the "manage" section.
        You can change the picture, edit the URL and more to make your NFT truly unique.
      </p>
      <img src={trackNFTImage} alt="track-nft" />
      <h1>Track NFT history</h1>
      <p>
        The Billionmaticpage also has a history page where previous NFTs are displayed.
      </p>
    </div>
  );
};
export default SecondColumn;
