import classNames from "classnames";
import PropTypes from "prop-types";
import OptionsButton from "../Layout/OptionsButton";

/**
 * NFT Card displayed in the Manage page, showing all
 * relevant info for a purchased/minted NFT.
 * 
 * @component
 * @param {number} id - Unique ID for this NFT. 
 * @param {number} x - X coordinate starting from top-left.
 * @param {number} y - Y coordinate starting from top-left.
 * @param {number} w - Width of the NFT, starting from 1.
 * @param {number} h - Height of the NFT, starting from 1.
 * @param {number} price - Current price of the NFT in Mattic.
 * @param {string} imageUrl - URL to the image that the NFT has.
 * @param {func} onEdit - Function that updates the metaadata.
 */
const NFTCard = ({
  id,
  x,
  y,
  w,
  h,
  price,
  imageUrl,
  onEdit,
}) => {
  const actionList = [
    {
      key: `edit-button-${id}`,
      class: "popover-button",
      text: "Edit",
      onClick: onEdit,
    },
  ];

  const priceText = price ? `${price.toFixed(2)} MATIC` : "N/A";

  return <div className="nft-card">
    <div className={classNames("nft-image-container", { "no-image": !imageUrl })}>
      <OptionsButton actionList={actionList} />
      {imageUrl && <img className="nft-image" src={imageUrl} alt="NFT" />}
    </div>
    <div className="nft-info">
      <div>
        NFT <span>#{id}</span>
      </div>
      <div>
        Coordinate <span>{x}/{y}</span>
      </div>
      <div>
        Size <span>{w}*{h}</span>
      </div>
      <div>
        Price <span className={classNames({"nft-price": price})}>{priceText}</span>
      </div>
    </div>
  </div>
};

NFTCard.propTypes = {
  id: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  price: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
};

export default NFTCard;