import ModalWrapper from "./ModalWrapper";
import PropTypes from "prop-types";
import flagImage from "../../images/flag.svg";
import Button from "./Button";

/**
 * Error modal for displaying error messages once
 * they happen.
 * 
 * @component
 * @param {string} error - Error message to display. If empty then hides the modal.
 * @param {func} setError - Function that sets the error to false. 
 */
const ErrorModal = ({
  error,
  setError,
}) => {
  return <ModalWrapper
    open={!!error}
    setOpen={setError}
  >
    <div className="alert-backdrop">
      <div className="alert">
        <div className="modal">
          <div className="modal-header">
            <div className="alert-header">
              <img
                src={flagImage}
                className="modal-image"
                alt="avatar"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="alert-body">
              <h2>Error!</h2>
              <p>
                {error}
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <Button
                onClick={() => setError(false)}
                buttonText={"OK"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>;
};

ErrorModal.propTypes = {
  error: PropTypes.any,
  setError: PropTypes.func.isRequired,
};

export default ErrorModal;
