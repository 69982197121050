import { useEffect, useState } from "react";
import NFTApiService from "../../api/NFTApiService";
import Loader from "../Layout/Loader";
import NFTMap from "../NFTMap/NFTMap";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import Footer from "../Layout/Footer";

/**
 * Homepage component that is displayed on index path.
 * The component fetches data from the back-end to obtain
 * everything needed for the NFTMap to display.
 * @component
 */
const Homepage = () => {
  const [nftData, setNftData] = useState(null);

  useEffect(() => {
    NFTApiService.GetHomeData().then((data) => setNftData(data));
  }, []);

  return (
    <>
      <Wrapper>
        <Header nftData={nftData} />
        {!!nftData ? (
          <NFTMap
            mapData={nftData.mapData}
            imageParts={nftData.imageParts}
            columnCount={nftData.divX}
            rowCount={nftData.divY}
            showZoomHeader={true}
          />
        ) : (
          <Loader />
        )}
        <Footer />
      </Wrapper>
    </>
  );
};

export default Homepage;
