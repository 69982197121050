import React from "react";
import PropTypes from "prop-types";
import "./wrapper.css";

/**
 * Wrapper that wraps the page into a container div
 * that is then used for stylizing.
 * Used by all main pages.
 * 
 * @param {any} children - The actual page that will be rendered.
 * @component
 */
const Wrapper = ({ children }) => {
  const onWrapperScroll = (e) => {
    if (e.isDefaultPrevented()) {
      return false;
    }
  };

  return <div
    className="wrapper"
    onWheel={onWrapperScroll}
  >
    {children}
  </div>;
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Wrapper;
