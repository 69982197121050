import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import Button from "../Layout/Button";
import ManageEditSidebarInfo from "./ManageEditSidebarInfo";
import ManageMetadata from "./ManageMetadata";
import closeImg from "../../images/close.svg";
import NFTApiService from "../../api/NFTApiService";
import { WalletContext } from "../../context/WalletContext";
import ChangeApprovalModal from "../Layout/ChangeApprovalModal";
import ErrorModal from "../Layout/ErrorModal";

/**
 * Sidebar that is displayed on the right when selecting
 * an NFT to edit on the Manage page.
 * Mobile version applies a class that makes the sidebar
 * be full-width on the screen.
 * If nft Id is undefined then the sidebar is not shown.
 * 
 * @component
 * @param {object} metadata - NFT data that needs to be edited.
 * @param {number} sellerFeePercent - 0.x% of the fee applied on sale of the NFT.
 * @param {func} onClose - Triggers when "X" is clicked. 
 */
const ManageEditSidebar = ({
  metadata,
  onClose,
}) => {
  const [error, setError] = useState(undefined);
  const [editMetadata, setEditMetadata] = useState(false);
  const [nftMetadata, setNftMetadata] = useState(metadata);
  const [signature, setSignature] = useState(undefined);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [sellerFeePercent, setSellerFeePercent] = useState(0);

  const {
    account,
    getSignature,
    sendPriceUpdateTransaction,
  } = useContext(WalletContext);

  useEffect(() => {
    setEditMetadata(false);

    if (metadata.id) {
      NFTApiService.GetNFTMetadata(metadata.id).then(({ nft, sellerFeePercent, sign }) => {
        setNftMetadata({
          ...metadata,
          ...nft,
        });
        setSignature(sign);
        setSellerFeePercent(sellerFeePercent);
      });
    }
  }, [metadata]);

  if (!metadata.id)
    return;

  const onUpdateImage = async (image) => {
    setNftMetadata({
      ...nftMetadata,
      imageUrl: image ? URL.createObjectURL(image) : "",
      imageFile: image,
    });
  };

  const onUpdateMetadata = async (nftId, metadata) => {
    // Blocks and waits for user approval.
    const walletSignature = await getSignature(signature, () => setError("Unable to get wallet signature."));
    metadata.image = nftMetadata.imageFile;

    await NFTApiService.PatchNFTMetadata(nftId, metadata, walletSignature.nonce, walletSignature.sign);

    setShowApprovalModal(true);
    setEditMetadata(false);
    setNftMetadata({
      ...nftMetadata,
      ...metadata,
    });
  };

  const onUpdatePrice = async (price) => {
    const patchedData = await NFTApiService.PatchNFTPrice(metadata.id, price);

    sendPriceUpdateTransaction(account, patchedData.transaction, (success) => {
      if (success) {
        setShowApprovalModal(true);
      } else {
        setError("Failed to update price on blockchain.");
      }
      setEditMetadata(false);
    });
  };

  return <div className="manage-sidebar">
    <ErrorModal error={error} setError={setError} />
    <ChangeApprovalModal show={showApprovalModal} handleClose={setShowApprovalModal} />

    <Button
      buttonSvg={closeImg}
      className="button-plain-style manage-sidebar-close"
      onClick={onClose}
    />

    <h1>Edit #{metadata.id}</h1>

    <ManageEditSidebarInfo
      nftMetadata={nftMetadata}
      isEditMode={editMetadata}
      onUpdateImage={onUpdateImage}
    />

    {editMetadata ?
      <ManageMetadata
        initialMetadata={nftMetadata}
        sellerFeePercent={sellerFeePercent}
        onUpdateMetadata={onUpdateMetadata}
        onSetPrice={onUpdatePrice}
        onRemoveFromSale={() => onUpdatePrice(0)}
      />
      :
      <Button
        className="button-outlined-style full-width"
        buttonText="Modify Metadata"
        onClick={() => setEditMetadata(true)}
      />
    }
  </div>
};

ManageEditSidebar.propTypes = {
  metadata: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ManageEditSidebar;