import WalletProvider from "./context/WalletContext";
import Routes from "./router/Routes";

function App() {
  return (
    <div className="App">
      <WalletProvider>
        <Routes />
      </WalletProvider>
    </div>
  );
}

export default App;