import React, { useContext, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import arrowDownImage from "../../images/arrow-down.svg";
import Button from "./Button";
import ConnectWalletModal from "./ConnectWalletModal";
import { WalletContext } from "../../context/WalletContext";
import { Drawer } from "./Drawer";
import menu from "../../images/menuIcon.svg";
import tradingIcon from "../../images/trading.svg";
import logoImage from "../../images/logo_white.png";


/**
 * Header navigation pane with tabs for different pages.
 * Used on all pages for navigation and for displaying
 * currently active page.
 * Also handles wallet integration and wallet information,
 * displaying the user's profile.
 * @component
 */
const Header = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { showWallet, showModal, setShowModal, handleChangeWallet, account } =
    useContext(WalletContext);
  const navLinkClasses = ({ isActive }) => (isActive ? "active" : "");
  const { pathname } = useLocation();
  const { nftData } = props;

  return (
    <div className="header-page">
      <ConnectWalletModal show={showModal} handleClose={setShowModal} />
      <div className="mobile">
        <img src={menu} alt="menu" onClick={() => setOpen(true)} />
        <Drawer anchor="left" open={open} onClose={() => setOpen(false)} />
      </div>
      <div className="desktop">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={logoImage} alt="billionmatic" />
        </div>
        {pathname === "/" && (
          <div className="trading">
           { nftData?.trades24h > 0 &&
           <div> <img src={tradingIcon} alt="trades" /> {nftData?.trades24h}{" "}
            trading/24 h
            </div>
        }
          </div>
        )}

        <div className="links">
          <NavLink to="/" className={navLinkClasses}>
            Home
          </NavLink>
          <NavLink to="/history" className={navLinkClasses}>
            History
          </NavLink>
          <NavLink to="/buy" className={navLinkClasses}>
            Buy
          </NavLink>
          <NavLink to="/mint" className={navLinkClasses}>
            Mint
          </NavLink>
          <NavLink to="/manage" className={navLinkClasses}>
            Manage
          </NavLink>
        </div>

        {!showWallet ? (
          <Button
            onClick={() => setShowModal(true)}
            buttonText={"Connect wallet"}
          />
        ) : (
          <div className="dropdown">
            <img src={arrowDownImage} alt="arrow" />
            <p>
              <span>Hi, </span> {account.substring(0, 6)}…{account.substring(account.length - 4)}
            </p>
            <div className="dropdown-content">
              <button
                className="header-connect-button"
                onClick={handleChangeWallet}
              >
                Disconnect wallet
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
