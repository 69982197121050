import Wrapper from "../Layout/Wrapper";
import FirstColumn from "./FirstColumn";
import "./footerPage.css";
import "./howItWorks.css";
import NumberSteps from "./NumberSteps";
import SecondColumn from "./SecondColumn";
import HowItWorksMobile from "./HowItWorksMobile";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

/**
 * How It Works component is part of the footer.
 * The component contains textual informations,
 * first column, number steps and second column
 * components and logo which navigates to homepage.
 * @component
 */
const HowItWorks = () => {
  return (
    <Wrapper>
      <Header />
      <div className="textual-page first-container">
        <div className="second-container">
          <div className="third-container">
            <div className="how-it-works">
              <h1>How the Billionmatic page Works</h1>
              <p>
                The Billionmaticpage provides a platform to buy, sell, and customize unique digital assets.
                With its user-friendly interface and built-in customization tools, it makes it easy for anyone to buy and manage their BillionMatic NFTs.
                Here's a step-by-step guide on how the Billionmaticpage works:
              </p>
            </div>
            <div className="columns">
              <div className="mobile">
                <HowItWorksMobile />
              </div>
              <div className="desktop">
                <FirstColumn />
                <NumberSteps />
                <SecondColumn />
              </div>
            </div>
          </div>
        </div>
        <div className="additional-information">
          <p>
           It's important to note that there may be fees associated with buying, selling, and customizing NFTs on the Billionmaticpage, such as transaction fees and gas fees.
           These fees are typically paid in MATIC and will vary depending on network congestion. It is recommended that you check the fee schedule on the Billionmaticpage
           website before making any transactions.
          </p>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default HowItWorks;
