import config from "../config/api.config";
import ApiService from "./ApiService";

/**
 * API Service that communicates with the main back-end
 * service, fetching data relevant to NFTs, their URLs, names,
 * their history, but also including ways to edit them.
 */
const NFTApiService = {
  /**
   * Gets the data needed for the homepage NFT map.
   * @returns Object containing the number of X/Y slots,
   * list of all image URLs and the list of all NFTs.
   */
  GetHomeData: async () => {
    return ApiService.Get(`${config.apiUrl}/home`);
  },

  /**
   * Gets timeline data for all dates for the History page.
   * @returns Object containing the number of X/Y slots,
   * list of all timelines and links to get NFT data
   * via the GetHistoricalNFTData.
   */
  GetHistoryData: async () => {
    return ApiService.Get(`${config.apiUrl}/history`);
  },

  /**
   * Gets the data needed for a single NFT timeline map.
   * Used for History view when an index is selected.
   * @param {string} dataUrl Sub-path to the selected NFT map.
   * @returns Object containing the list of all NFTs
   * for the specified timeline.
   */
  GetHistoricalNFTData: async (dataUrl) => {
    return ApiService.Get(`${dataUrl}`);
  },

  /**
   * Gets data needed for minting, which also includes
   * the price and count of minting and the transaction
   * info needed for wallet transactions.
   * @returns  Object containing NFT data, alongside
   * minting data.
   */
   GetMintData: async (walletAddress) => {
    const apiUrl = walletAddress ? `${config.apiUrl}/mint/${walletAddress}` : `${config.apiUrl}/mint`;
    return ApiService.Get(apiUrl);
  },
  /**
   * Gets NFTs owned (with their details) for one specific
   * wallet address. Gets data needed for Manage page.
   * @param {string} walletAddress - Address (without 0x) of the wallet.
   * @returns Object with nfts array containing owned NFTs.
   */
  GetManageData: async (walletAddress) => {
    return ApiService.GetById(walletAddress, `${config.apiUrl}/manage`);
  },

  /**
   * Gets the metadata of a specific NFT (width, height, coordinates, name, url, etc.)
   * and also gets the signing information needed to make PATCH requests to
   * update the metadata.
   * @param {number} nftId - ID of the specific NFT.
   * @returns Object with NFT metadata and signing information.
   */
  GetNFTMetadata: async (nftId) => {
    return ApiService.GetById(nftId, `${config.apiUrl}/metadata`);
  },

  /**
   * Updates the metadata of a specific NFT in order to update one of its
   * fields like name, URL and image.
   * @param {number} nftId - ID of the NFT that needs to be updated.
   * @param {object} metadata - New NFT metadata that will be set. 
   * @param {string} nonce - Token obtained from back-end via GET.
   * @param {string} signature - Wallet signature obtained with getSignature.
   * @returns Object that contains the new NFT metadata, status of the image and
   * general status of whether the update worked.
   */
  PatchNFTMetadata: async (nftId, metadata, nonce, signature) => {
    const formData = new FormData();
    formData.append("nonce", nonce);
    formData.append("signature", signature);
    Object.keys(metadata).forEach(metadataKey => formData.append(metadataKey, metadata[metadataKey]));

    return ApiService.Update(nftId, `${config.apiUrl}/metadata`, formData);
  },

  /**
   * Updates the price data of a specific NFT. Only price is passed.
   * Transaction data is returned so wallet update is possible.
   * @param {number} nftId - ID of the NFT that needs to be updated.
   * @param {number} price - New price as a float.
   * @returns Object that contains the new NFT price data, status of the update
   * and transaction data that is used to update on wallet.
   */
  PatchNFTPrice: async (nftId, price) => {
    return ApiService.Update(nftId, `${config.apiUrl}/price`, {
      price,
    });
  },
  /**
   * Gets data needed for buying and
   * @returns  Object containing NFT data.
   */
  GetBuyData: async () => {
    return ApiService.Get(`${config.apiUrl}/buy`);
  },
  /**
   * Gets detailed data needed for buying one nft
   * @returns  Object containing NFT data, imageUrl
   * and transaction informations.
   */
  GetBuyDataId: async (id) => {
    return ApiService.Get(`${config.apiUrl}/Buy/${id}`);
  },
};

export default NFTApiService;
