import { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * The visible, but static, part of the NFTMap, which
 * displays a list of image parts joined together into
 * one large high-quality image.
 * 
 * @component
 * @param {array} imageParts - Array of image URLs that need to be downloaded. 
 * @param {func} onLoad - Function called when all images have been loaded.
 */
const NFTMapImage = ({
  imageParts,
  onLoad,
  style,
  isSingleMap,
}) => {
  const imageLoadCount = useRef(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onImageLoad = () => {
    imageLoadCount.current++;
    if (imageLoadCount.current >= imageParts.length) {
      setHasLoaded(true);
      onLoad();
    }
  };

  return <div
    className={classNames(
      "nft-map-image",
      { "nft-map-image--loaded": hasLoaded },
      { "single-map": isSingleMap },
    )}
    style={style}
  >
    {imageParts.map((imageUrl, idx) => (
      <img
        key={`image-part-${idx}`}
        onLoad={onImageLoad}
        className="image-part"
        src={`${imageUrl}`}
        draggable={false}
        alt=""
      />
    ))}
  </div>;
};

NFTMapImage.propTypes = {
  imageParts: PropTypes.array.isRequired,
  onLoad: PropTypes.func.isRequired,
  isSingleMap: PropTypes.bool,
};

export default NFTMapImage;
