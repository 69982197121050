import axios from "axios";

/**
 * Private helper for mapping key-value pairs so they
 * can be prepared for an API call.
 * @param {object} qp Query Parameters that need to mapped.
 * @returns Array of key-value pairs.
 */
const mapQueryParamsToUrl = (qp) => {
  return Object.keys(qp).map((key) => {
    return `${key}=${qp[key]}`;
  });
};

/**
 * Private helper for preparing parameters for API calls.
 * @param {object} qp Query Parameters that need to be parsed.
 * @returns Prepared query string for a call.
 */
const correctFormatForQueryUrl = (qp) => {
  if (qp === null) {
    return "";
  }
  const qpAsStr = mapQueryParamsToUrl(qp);
  const returnValue = qpAsStr.length === 0 ? "" : `?${qpAsStr.join("&")}`;
  return returnValue;
};

/**
 * Private helper for handling error responses.
 * @param {object} error Object containing the error response.
 * @returns Object containing the error message.
 */
const handleError = (error) => {
  if (error?.response) {
    return error.response;
  } else {
    return { error: "An unknown error happened" };
  }
};

/**
 * Template API Service that handles the basic calls
 * made to an API. It is meant to be used by other
 * services (like NFTApiService) that pass the route
 * and use one of the relevant call types.
 */
const ApiService = {
  Get: async(route, qp = {}) => {
    const queryString = correctFormatForQueryUrl(qp);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          `${route}${queryString}`
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  GetById: async(id, route) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(`${route}/${id}`);
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Insert: async (route, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(`${route}`, data);
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Update: async(id, route, data)=> {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const result = await axios.post(
          `${route}/${id}`,
          data,
          { headers },
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
  Delete: async (id, route) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(
          `${route}/${id}`
        );
        resolve(result.data);
        return result.data;
      } catch (error) {
        reject(handleError(error));
      }
    });
  },
};

export default ApiService;
