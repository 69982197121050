import PropTypes from "prop-types";
import ModalWrapper from "./ModalWrapper";
import "./modal_wrapper.css";

/**
 * Generic modal class for using in other custom modals.
 * Takes three separate HTML/React elements to render the header, body and footer.
 * 
 * @component
 * @param {object} header - Element shown in the header.
 * @param {object} body - Element shown in the body.
 * @param {object} footer - Element shown in the footer.
 * @param {bool} show - Whether to show the modal or not.
 * @param {func} handleClose - Function used for toggling modal visibility.
 * @param {string} wrapperClassname - Class of the modal wrapper.
 * @param {string} className - Class of the modal child. 
 */
const Modal = ({
  header,
  body,
  footer,
  show,
  handleClose,
  wrapperClassname,
  className,
}) => {
  return (
    <ModalWrapper
      open={show}
      setOpen={handleClose}
      className={wrapperClassname}
      childClassName={className}
    >
      <div className="alert-backdrop">
        <div className="alert">
          <div className="modal">
            <div className="modal-header">{header}</div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

Modal.propTypes = {
  header: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  wrapperClassname: PropTypes.string,
  className: PropTypes.string,
};

export default Modal;
