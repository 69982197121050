import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useWindowSize } from "../../helpers/common";
import axisImgSrc from "./../../images/slider-axis-line.svg";

const SLIDER_PADDING = 24;      // Left and right padding for the slider
const SLIDER_THUMB_WIDTH = 19;  // Width of .history-slider::-webkit-slider-thumb

/**
 * Timeline slider component that is used to go through
 * different timelines of the NFT Map.
 * It takes the currently selected index/timeline and shows it
 * on a slider that has axis points depending on the total number of
 * indices and screen width.
 * 
 * @component
 * @param {number} selectedIndex - Currently selected part of the slider.
 * @param {func} setSelectedIndex - Updates the currently selected index.
 * @param {number} totalIndices -  Total number of timelines.
 * @param {object} selectedTimeline - Data about the timeline with the selected index.
 */
const TimelineSlider = ({ 
  selectedIndex, 
  setSelectedIndex, 
  totalIndices,
  selectedTimeline,
}) => {
  const windowSize = useWindowSize();
  const timelineSlider = useRef();

  let sliderPercentage = 0; // Index/(Length - 1)
  let sliderLeft = 0;       // Fill pixels from the left to this value
  let sliderPageOffsetLeft = 0; // Offset of the slider due to page gaps
  let renderAxisLines = [];

  useEffect(() => {
    timelineSlider.current.focus();
  }, [selectedIndex])

  if (timelineSlider.current) {
    const sliderWorkableWidth = timelineSlider.current.offsetWidth - SLIDER_PADDING * 2;

    sliderPercentage = selectedIndex / (totalIndices - 1);
    const thumbOffset = SLIDER_THUMB_WIDTH / 2 * (1 - 2 * sliderPercentage);
    sliderLeft = SLIDER_PADDING + sliderWorkableWidth * sliderPercentage + thumbOffset;
    sliderPageOffsetLeft = timelineSlider.current.offsetLeft;

    let axisStep = Math.ceil(totalIndices / 60);
    if (windowSize.width < 800) {
      axisStep *= 4;
    }
    renderAxisLines = [...Array(totalIndices)].map((_, idx) => {
      if (idx % axisStep !== 0) {
        return "";
      }

      const offsetByIdx = (sliderWorkableWidth * idx) / (totalIndices - 1);
      const sliderThumbOffsetByIdx = SLIDER_THUMB_WIDTH / 2 * (1 - 2 * idx / (totalIndices - 1));
      const leftPosition = SLIDER_PADDING + offsetByIdx + sliderThumbOffsetByIdx - 3;

      return (
        <div
          key={`axis-${idx}`}
          className="history-slider-axis-line"
          style={{ left: `${leftPosition}px` }}
        >
          <img src={axisImgSrc} alt="" />
        </div>
      )
    });
  }

  const setDateFormat = (unformattedDate) => {
    const date = new Date(unformattedDate);
    if (isNaN(date))
      return "";

    return date.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const onSliderChange = (e) => {
    let value = parseInt(e.target.value);
    let incrementChange = Math.sign(e.deltaY || 0);
    if (e.type === "keydown") {
      if (e.code === "KeyA") incrementChange--;
      if (e.code === "KeyD") incrementChange++;
      if (e.code === "PageUp") incrementChange--;
      if (e.code === "PageDown") incrementChange++;
      if (e.code === "Home") value = 0;
      if (e.code === "End") value = totalIndices - 1;
      // Handle digit keys 0-9
      if (isFinite(parseInt(e.key))) value = Math.floor(parseInt(e.key) * (totalIndices - 1 / 9)) % (totalIndices);
    }

    const newIndex = value + incrementChange;
    setSelectedIndex(Math.max(0, Math.min(newIndex, totalIndices - 1)));
  };

  return <div className="history-slider-container">
    <input
      type="range"
      min="0"
      max={totalIndices - 1}
      value={selectedIndex}
      className="history-slider"
      onChange={onSliderChange}
      onWheel={onSliderChange}
      onKeyDown={onSliderChange}
      ref={timelineSlider}
      style={{
        background: `linear-gradient(
                to right, 
                #691CDF 0,
                #B441FF ${sliderLeft}px,
                rgba(0, 0, 0, 0.3) ${sliderLeft}px,
                rgba(0, 0, 0, 0.3) 100%
            )`,
        padding: `0 ${SLIDER_PADDING}px`,
      }}
    />
    <div
      className="history-slider-axis-lines"
      style={{
        left: `calc(${sliderPageOffsetLeft}px)`,
      }}
    >
      {renderAxisLines}
    </div>

    <div
      className="date-tooltip"
      style={{
        left: `calc(${sliderLeft}px - 31px + ${sliderPageOffsetLeft}px)`,
      }}
    >
      {setDateFormat(selectedTimeline.date)}
    </div>
  </div>;
};

TimelineSlider.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  totalIndices: PropTypes.number.isRequired,
  selectedTimeline: PropTypes.object.isRequired,
};

export default TimelineSlider;