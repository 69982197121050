import createWalletImage from "../../images/createWallet.svg";
import purchaseNFTImage from "../../images/purchaseNFT.svg";
import listNFTImage from "../../images/listNFT.svg";
import browseNFTImage from "../../images/browseNFT.svg";
import customizeNFTImage from "../../images/customizeNFT.svg";
import trackNFTImage from "../../images/trackNFT.svg";
import { useState } from "react";

/**
 * How It Works Mobile displays the version
 * of How It Works page from footer for the devices
 * under 900px.
 * It contains text and images.
 * @component
 */
const HowItWorksMobile = () => {
  const [activeCircle, setActiveCircle] = useState(1);

  const handleClick = (id) => {
    setActiveCircle(id);
  };
  return (
    <>
      <div className="mobile-wrapper">
        <div
          className={`circle ${activeCircle === 1 ? "active" : ""}`}
          data-id="1"
          onClick={() => handleClick(1)}
        >
          1
        </div>
        <h1>Create a Wallet</h1>
        <p>
          To use the Billionmaticpage, you will need to have a MATIC/Polygon wallet, which you can install within the chrome browser.
          This wallet will be used to store and manage your NFTs. You can use for example MetaMask or TrustWallet.
        </p>
        <img src={createWalletImage} alt="create-wallet" />
        <div
          className={`circle ${activeCircle === 2 ? "active" : ""}`}
          data-id="2"
          onClick={() => handleClick(2)}
        >
          2
        </div>
        <h1>Browse the available NFTs</h1>
        <p>
           Once you have a polygon wallet, you can browse the available NFTs on the main page of the Billionmaticpage.
           Each NFT is unique and has its own set of characteristics and attributes (location, size etc.). 
        </p>
        <img src={browseNFTImage} alt="browse-nft" />
        <div
          className={`circle ${activeCircle === 3 ? "active" : ""}`}
          data-id="3"
          onClick={() => handleClick(3)}
        >
          3
        </div>
        <h1>Purchase an NFT</h1>
        <p>
           To purchase an NFT, you will need to have some MATIC in your wallet.
           Once you have found an NFT you would like to own, simply navigate to the "buy" section, click on the "buy" button and confirm the transaction in your wallet.
        </p>
        <img src={purchaseNFTImage} alt="purchase-nft" />
        <div
          className={`circle ${activeCircle === 4 ? "active" : ""}`}
          data-id="4"
          onClick={() => handleClick(4)}
        >
          4
        </div>
        <h1>Customize your NFT</h1>
        <p>
          Once you have an NFT, you can customize its appearance using the built-in customization tools on the "manage" section.
          You can change the picture, edit the URL and more to make your NFT truly unique.
        </p>
        <img src={customizeNFTImage} alt="customize-nft" />
        <div
          className={`circle ${activeCircle === 5 ? "active" : ""}`}
          data-id="5"
          onClick={() => handleClick(5)}
        >
          5
        </div>
        <h1>List your NFT for sale</h1>
        <p>
          If you would like to sell your NFT, you can list it for sale on the Billionmaticpage.
          Other users can then browse and purchase your NFT.
        </p>
        <img src={listNFTImage} alt="list-nft" />
        <div
          className={`circle ${activeCircle === 6 ? "active" : ""}`}
          data-id="6"
          onClick={() => handleClick(6)}
        >
          6
        </div>
        <h1>Track NFT history</h1>
        <p>
          The Billionmaticpage also has a history page where previous NFTs are displayed.
        </p>
        <img src={trackNFTImage} alt="track-nft" />
      </div>
    </>
  );
};
export default HowItWorksMobile;
