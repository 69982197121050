import PropTypes from "prop-types";
import ZoomOptions from "../NFTMap/ZoomOptions";
import "./homepage.css";

/**
 * The component between the header and the NFTMap present
 * on the homepage.
 * 
 * @component
 * @param {func} onZoom - Zoom function triggered on zoom button click.
 */
const HomepageTitle = ({ onZoom }) => {
  return <div className="homepage-title">
    <h1>
      Own a piece of blockchain history
    </h1>
    <ZoomOptions 
      onZoom={onZoom}
    />
  </div>;
}

HomepageTitle.propTypes = {
  onZoom: PropTypes.func.isRequired,
};

export default HomepageTitle;
