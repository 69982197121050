import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./modal_wrapper.css";

/**
 * Wrapper used for the Modal class to hide away the implementation
 * for backdrop click and other behind-the-scenes modal setup.
 * The actual modal is given as the children prop.
 * 
 * @component
 * @param {object} className - Additional classname for the wrapper.
 * @param {bool} dismissible - Whether the modal can be dismissed on backdrop click.
 * @param {object} style - Style for the main wrapper where backdrop is handled.
 * @param {string} childStyle - Style for the inner child wrapper.
 * @param {string} childClassName - Additional classname for the child wrapper.
 * @param {bool} open - Whether to show the modal or not.
 * @param {func} setOpen - Function that sets the visibility of the modal.
 * @param {object} children - Actual modal that is shown in the wrapper. 
 */
const ModalWrapper = ({
  className,
  dismissible = true,
  style,
  childStyle,
  childClassName,
  open,
  setOpen,
  children,
}) => {
  const onBackdropClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (dismissible) {
        setOpen(false);
      }
    },
    [dismissible, setOpen]
  );

  const onModalBodyClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  if (!open) {
    return null;
  }

  return (
    <div
      onClick={onBackdropClick}
      style={style}
      className={classNames("modal-wrapper", className, { modalOpen: open })}
    >
      <div
        onClick={onModalBodyClick}
        style={childStyle}
        className={classNames("modal", childClassName, { open: open })}
      >
        {children}
      </div>
    </div>
  );
};

ModalWrapper.propTypes = {
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  style: PropTypes.object,
  childStyle: PropTypes.object,
  childClassName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default ModalWrapper;
