import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ADDRESS_REGEX } from "../../helpers/common";
import "./mint.css";
import classNames from "classnames";
/**
 * Header for the Mint page that has the Mint button and
 * the optional receiver address.
 * Used also on Buy page that has the Buy button and
 * the optional receiver address. *
 *
 * @component
 * @param {number} mintPrice - Current minting price from back-end.
 * @param {number} mintCount - Current number of remaining mintable plots from back-end.
 * @param {func} onClick - Executed when Mint/Buy button used, passing the receiver value, too.
 * @param {string} disabledMessage - Disables the button with a message.
 */
const OptionalAddress = ({ mintCount, mintPrice, onClick, disabledMessage }) => {
  const [isReceiverShown, setReceiverShown] = useState(false);
  const [currentReceiver, setCurrentReceiver] = useState("");
  const [onBuyPage, setOnBuyPage] = useState(false);
  const addressInput = useRef(null);

  const showReceiverInput = (e) => {
    setReceiverShown(true);
  };

  const validateAndSubmitAddress = () => {
    if (isAddressValid(currentReceiver) || !currentReceiver) {
      onClick(currentReceiver);
    }
  };

  const onInputBlur = () => {
    if (!currentReceiver) {
      setReceiverShown(false);
    } else if (!isAddressValid(currentReceiver)) {
      addressInput.current.classList.add("address-error");
    } else {
      addressInput.current.classList.remove("address-error");
    }
  };

  const isAddressValid = (address) => ADDRESS_REGEX.test(address);

  useEffect(() => {
    if (addressInput.current) {
      addressInput.current.focus();
    }
    checkLocation();
  }, [isReceiverShown]);

  const checkLocation = () => {
    if (window.location.pathname.startsWith("/buy")) {
      setOnBuyPage(true);
    }
  };

  let buttonMessage = "Mint now!";
  if (onBuyPage) {
    buttonMessage = "Buy now!";
  }
  if (disabledMessage) {
    buttonMessage = disabledMessage;
  }

  return (
    <div
      className={onBuyPage ? "address-container-buy " : "address-container"}
    >
      {isReceiverShown ? (
        <input
          ref={addressInput}
          className={onBuyPage ? "metadata-field address-input-buy" : "address-input"}
          type="text"
          placeholder="Address"
          value={currentReceiver}
          onFocus={() =>
            addressInput.current.classList.remove("address-error")
          }
          onChange={(e) => setCurrentReceiver(e.target.value)}
          onBlur={onInputBlur}
        />
      ) : (
        <button
          onClick={() => showReceiverInput(currentReceiver)}
          className="add-address"
        >
          + Add receiver address <span>(optional)</span>
        </button>
      )}
      <button
        onClick={validateAndSubmitAddress}
        disabled={!!disabledMessage}
        className={classNames(
          "button-predefined-style",
          {
            "buy-button": onBuyPage,
            "mint-button": !onBuyPage,
            "buy-disabled": !!disabledMessage,
          }
        )}
      >
        {buttonMessage}
      </button>{" "}
    </div>
  );
};

OptionalAddress.propTypes = {
  mintCount: PropTypes.number,
  mintPrice: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  disabledMessage: PropTypes.string,
};

export default OptionalAddress;
