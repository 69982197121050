import { useContext, useEffect, useState } from "react";
import NFTApiService from "../../api/NFTApiService";
import { WalletContext } from "../../context/WalletContext";
import ConnectWalletModal from "../Layout/ConnectWalletModal";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import "./manage.css";
import ManageSidebar from "./ManageSidebar";
import NFTList from "./NFTList";

/**
 * Page that shows currently bought NFTs, their info and options.
 * @component
 */
const Manage = () => {
  const [nftList, setNftList] = useState(undefined);
  const [selectedNft, setSelectedNft] = useState(undefined);

  const {
    account,
    showWallet,
    showModal,
    setShowModal,
  } = useContext(WalletContext);

  useEffect(() => {
    if (!account && !showModal) {
      setShowModal(true);
    }
  }, [account, showModal, setShowModal]);

  useEffect(() => {
    if (showWallet && account) {
      NFTApiService.GetManageData(account.substring(2)).then(data => {
        setNftList(data.nfts);
      });
    }
  }, [showWallet, account, setNftList]);

  return <Wrapper>
    <ConnectWalletModal show={showModal} handleClose={setShowModal} />
    <Header />
    <div className="manage-body">
      {nftList && account && (
        <NFTList
          nftList={nftList}
          onEditNft={nft => setSelectedNft(nft)}
        />
      )}
      {selectedNft && (
        <ManageSidebar
          nftId={selectedNft?.id}
          metadata={selectedNft}
          onClose={() => setSelectedNft(undefined)}
        />
      )}
    </div>
    <Footer />
  </Wrapper>;
};

export default Manage;
