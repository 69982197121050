import Wrapper from "../Layout/Wrapper";
import "./footerPage.css";
import lionImage from "../../images/Lion.jpeg";
import thomasImage from "../../images/Thomas.jpeg";
import linkedInLogo from "../../images/linkedIn.svg";
import xingLogo from "../../images/xing.svg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

/**
 * About Us component is part of the footer.
 * The component contains logo, textual informations
 * as well as informations and photos of the team members.
 * @component
 */
const AboutUs = () => {
  return (
    <Wrapper>
      <Header />
      <div className="textual-page">
        <div className="about-us-page">
          <h1>About us</h1>
          <p className="about-us-text">
            We are a well experienced founding team with a passion for the world of NFTs and crypto.
            Our team is composed of experienced professionals in the field of technology, IT and marketing, working together to bring NFTs to the masses with the billionmaticpage.
          </p>
        </div>
        <div className="our-team">
          <h1>Our team</h1>
          <div className="cards">
            <div className="team-card">
              <div className="team-card-picture">
                <img src={lionImage} alt="team-member" />
              </div>
              <h1>Lion Harisch</h1>
              <p className="position">Co-Founder</p>
              <div className="social-media-page">
                <a href=" https://www.linkedin.com/in/lion-harisch-96a413145/">
                  <img src={linkedInLogo} alt="social-media-logo" />
                </a>
              </div>
              <p>
                Lion is a Marketing Expert with many years of experience in the marketing industry, Lion has a wealth of knowledge in creating and implementing successful
                marketing strategies. He is a co-founder of Yoummday, a successful customer service platform. He has a passion for the world of NFTs and is dedicated to
                making the Billionmaticpage user experience as seamless as possible.
              </p>
            </div>
            <div className="team-card">
              <div className="team-card-picture">
                <img src={thomasImage} alt="team-member" />
              </div>
              <h1>Thomas Wallner</h1>
              <p className="position">Co-Founder</p>
              <div className="social-media-page">
                <a href="https://www.xing.com/profile/Thomas_Wallner12">
                  <img src={xingLogo} alt="social-media-logo" />
                </a>
              </div>
              <p>
                Thomas is a well experienced developer and IT professional with a wealth of knowledge in the technology behind NFTs.
                He was one of the first employees of Yoummday before being promoted as its CTO, where he has lead the strategic development of various web and mobile applications.
                He has a passion for blockchain and the potential it holds for the future of digital ownership.
              </p>
            </div>
          </div>
          <p className="about-us-paragraph">
            Together, Lion and Thomas bring a unique set of skills and expertise to the Billionmaticpage project. With their combined experience in technology,
            marketing and entrepreneurship, they are committed to creating a user-friendly and innovative NFT platform. 
          </p>
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default AboutUs;
