import PropTypes from "prop-types";

/**
 * Represents a blueprint for individual NFT icons present
 * on the large NFTMap, with each being hoverable and
 * clickable, but also invisible since the background image
 * as a whole displays all NFT images together.
 *
 * @component
 * @param {number} id - Back-end ID of the NFT.
 * @param {string} name - Tooltip title shown.
 * @param {number} x - Integer X coordinate having a range [1, allNftData.divX-1].
 * @param {number} y - Integer Y coordinate having a range [1, allNftData.divY-1].
 * @param {number} w - Integer width having a range [1, allNftData.divX-1].
 * @param {number} h - Integer height having a range [1, allNftData.divY-1].
 * @param {string} url - URL that is openned in a new tab on click. Can be undefined.
 * @param {func} onClick - Listener that is executed when the NFT is clicked. Handled by parent.
 * @param {number} widthPerc - Percentage of total width used by one NFT with width of 1.
 * @param {number} heightPerc - Percentage of total height used by one NFT with height of 1.
 * @param {bool} isDisabled - Whether to disable an NFT or not. Won't be clickable.
 * @param {bool} showAdditionalInfo - Whether to show tooltips and have special classes for NFTs.
 * @param {bool} showAsMintable - Render a "?" instead of an invisible element.
 */
const NFT = ({
  id,
  name,
  x,
  y,
  w,
  h,
  url,
  onClick,
  widthPerc,
  heightPerc,
  isDisabled,
  showAdditionalInfo,
  showAsMintable,
}) => {
  let classNames = [];
  let tooltipText = name;

  if (isDisabled) {
    classNames.push("nft--disabled");
  } else {
    classNames.push("nft");
  }

  if (showAsMintable) {
    classNames.push("nft--mintable-plot");
  }
  if (showAdditionalInfo) {
    if (typeof id === "undefined") {
      // No ID means NFT is mintable.
      classNames.push("nft--empty");
      tooltipText = "Mint now!";
    } else if (typeof url === "undefined") {
      // ID, but no URL, means NFT is taken.
      classNames.push("nft--taken");
      tooltipText = "Already taken!";
    }
  }

  return (
    <div
      key={name}
      onClick={onClick}
      className={classNames.join(" ")}
      title={tooltipText}
      style={{
        left: `calc(${x}% * ${widthPerc})`,
        top: `calc(${y}% * ${heightPerc})`,
        width: `calc(${w}% * ${widthPerc})`,
        height: `calc(${h}% * ${heightPerc})`,
      }}
    />
  );
};

NFT.propTypes = {
  name: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  widthPerc: PropTypes.number.isRequired,
  heightPerc: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  showAdditionalInfo: PropTypes.bool,
  showAsMintable: PropTypes.bool,
};

export default NFT;
