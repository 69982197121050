import PropTypes from "prop-types";
import EmptyNFTList from "./EmptyNFTList";
import NFTCard from "./NFTCard";

/**
 * A container of a list of NFT Cards displayed on the Manage page.
 * 
 * @component
 * @param {array} nftList - List of NFTs containing all needed info for an NFT card.
 * @param {func} onEditNft - Triggered once an NFT is selected for editing.
 */
const NFTList = ({ nftList, onEditNft }) => {
  if (nftList.length === 0) {
    return <EmptyNFTList />;
  }

  const renderedNfts = nftList.map((nft, idx) => (
    <NFTCard
      key={`nft-card-${idx}`}
      {...nft}
      onEdit={() => onEditNft(nft)}
    />
  ));

  return <div className="manage-nfts-container">
    {renderedNfts}
  </div>
};

NFTList.propTypes = {
  nftList: PropTypes.array.isRequired,
  onEditNft: PropTypes.func,
};

export default NFTList;