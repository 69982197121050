import classNames from "classnames";
import PropTypes from "prop-types";
import EditableImage from "../Layout/EditableImage";

/**
 * Shows info related to a specific NFT, used to display
 * info prior to edit on the Manage page.
 * Shows image, id, coordinate and size.
 * 
 * @component
 * @param {object} nftMetadata - Info of the NFT to show.
 * @param {bool} isEditMode - Whether to add option to change image.
 * @param {func} onUpdateImage - To update the image URL with a new one.
 */
const ManageEditSidebarInfo = ({ nftMetadata, isEditMode, onUpdateImage }) => {
  const { id, x, y, w, h, price, imageUrl } = nftMetadata;

  const onUpdate = async (image) => {
    onUpdateImage(image);
  };

  // const onRemove = () => {
  //   onUpdateImage("");
  // };

  const priceText = price ? `${price.toFixed(2)} MATIC` : "N/A";

  return <div className="manage-sidebar-info">
    <EditableImage
      src={imageUrl}
      isEditMode={isEditMode}
      onUpdateImage={onUpdate}
      // onRemoveImage={onRemove}
    />

    <div className="nft-info">
      <div>
        NFT <span>#{id}</span>
      </div>
      <div>
        Coordinate <span>{x}/{y}</span>
      </div>
      <div>
        Size <span>{w}*{h}</span>
      </div>
      <div className="nft-info-price">
        Price <span className={classNames({ "nft-price": price })}>{priceText}</span>
      </div>
    </div>
  </div>
};

ManageEditSidebarInfo.propTypes = {
  nftMetadata: PropTypes.object,
  isEditMode: PropTypes.bool,
  onUpdateImage: PropTypes.func,
};

export default ManageEditSidebarInfo;