import { useEffect, useState } from "react";

/**
 * Function that debounces/throttles and removes noise
 * from components that can trigger too often.
 * 
 * Example being the mousewheel being used to zoom, which
 * can generate a lot of function calls of something that
 * is expensive to run, resulting in instability.
 * 
 * @param {func} func - Function that will be throttled. 
 * @param {number} timeout - Amount of milliseconds between each throttled call.
 * @returns Original function with added timer that throttles calls.
 */
export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

/**
 * Handles the window dimensions used for responsiveness.
 * Attaches a "resize" event listener so the dimensions are updated
 * even if the browser is resized.
 * 
 * @returns Object with width and height values of the window.
 */
export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onResize = () => {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener("resize", onResize);

      onResize();

      return () => window.removeEventListener("resize", onResize);
    }
  }, []);

  return size;
};

export const ADDRESS_REGEX = /^0x[0-9a-fA-F]{40}$/;
