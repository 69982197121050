import PropTypes from "prop-types";
import Button from "./Button";
import editIcon from "../../images/edit.svg";
import { createRef, useState } from "react";
import PopoverButtonList from "./PopoverButtonList";
import classNames from "classnames";

/**
 * Component that shows an image and shows options
 * for changing and removing it, depending if edit mode.
 * 
 * @component
 * @param {string} src - Initial source URL for the image.
 * @param {bool} isEditMode - Whether to show edit/remove options.
 * @param {func} onUpdateImage - Calls parent to update image with given file.
//  * @param {func} onRemoveImage - Calls parent to remove image.
 */
const EditableImage = ({
  src,
  isEditMode,
  onUpdateImage,
  // onRemoveImage,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const inputFile = createRef(undefined);

  const onShowFileBrowser = () => {
    inputFile.current.click();
  };

  const onFileSelected = (event) => {
    const selectedFile = event.target.files[0];

    onUpdateImage(selectedFile);
  };

  const actionList = [
    {
      key: "change-picture-button",
      class: "popover-button",
      text: "Change picture",
      onClick: onShowFileBrowser,
    },
    // {
    //   key: "remove-picture-button",
    //   class: "popover-button popover-danger",
    //   text: "Remove picture",
    //   onClick: onRemoveImage,
    // },
  ];

  return <div className={classNames("manage-sidebar-info-image", { "no-image": !src })}>
    <input type="file" ref={inputFile} style={{ display: "none" }} onChange={onFileSelected} />

    {src && (
      <img className="manage-nft-image" src={src} alt="NFT" />
    )}

    <PopoverButtonList
      show={showPopup}
      setShow={setShowPopup}
      actionList={actionList}
    />

    {isEditMode && (
      <Button
        className="manage-nft-image-edit"
        tooltipText="Upload image"
        onClick={() => {
          if (actionList.length === 1) {
            actionList[0].onClick();
          } else {
            setShowPopup(true);
          }
        }}
        buttonSvg={editIcon}
      />
    )}
  </div>;
};

EditableImage.propTypes = {
  src: PropTypes.string,
  isEditMode: PropTypes.bool,
  onUpdateImage: PropTypes.func,
  // onRemoveImage: PropTypes.func,
};

export default EditableImage;