import React, { useContext } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "./ModalWrapper";
import Button from "./Button";
import walletImage from "../../images/wallet.svg";
import { WalletContext } from "../../context/WalletContext";

/**
 * Modal with wallet connection info, i.e. shows instructions
 * for how the user can install a wallet app OR how to connect the wallet.
 * Displayed whenever the user wants to connect a wallet or when
 * minting/purchasing an NFT.
 * 
 * @component
 * @param {bool} show - Show the modal or not.
 * @param {func} handleClose - Function executed on background click.
 * @param {string} className - Class name of the child.
 * @param {string} wrapperClassname -  Class name of the wrapper.
 */
const ConnectWalletModal = ({
  show,
  handleClose,
  className,
  wrapperClassname,
}) => {
  const { isWalletAppInstalled, connectWallet } = useContext(WalletContext);
  return (
    <ModalWrapper
      open={show}
      setOpen={handleClose}
      className={wrapperClassname}
      childClassName={className}
    >
      <div className="alert-backdrop">
        <div className="alert">
          <div className="modal">
            <div className="modal-header">
              <div className="alert-header">
                <img
                  src={walletImage}
                  className="modal-image"
                  alt="avatar"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="alert-body">
                <h2>Connect your wallet!</h2>
                {!isWalletAppInstalled ? (
                  <p>
                    In order to buy your NFT, you need to connect your wallet.
                  </p>
                ) : (
                  <>
                    <p>
                      In order to connect your wallet, you need to install
                      Wallet first!
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div>
                {!isWalletAppInstalled ? (
                  <Button
                    onClick={() => connectWallet()}
                    buttonText={"Connect Wallet"}
                  />
                ) : (
                  <Button
                    onClick={() => window.open("https://metamask.io/download/")}
                    buttonText={"Install MetaMask Wallet"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

ConnectWalletModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  wrapperClassname: PropTypes.string,
};

export default ConnectWalletModal;
