import { useNavigate } from "react-router-dom";
import emptyFolderImage from "../../images/empty-folder.svg"
import Button from "../Layout/Button";

/**
 * Empty list of NFTs shown in the Manage page.
 * Contains text info and a button to the mint page.
 * @component
 */
const EmptyNFTList = () => {
  const navigate = useNavigate();

  const onGetNFTs = () => {
    navigate("/mint");
  };

  return <div className="empty-nft-list">
    <img src={emptyFolderImage} alt="No NFTs" />
    <div>
      <h2>No NFTs yet!</h2>
      <p>
        Seems like you don't have anything to manage yet.<br />
        Buy some NFTs and do the magic!
      </p>
    </div>
    <Button
      onClick={onGetNFTs}
      buttonText="Get your NFTs"
      className="button-filled-style"
    />
  </div >
};

export default EmptyNFTList;