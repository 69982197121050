import { useEffect, useState } from "react";
import NFTApiService from "../../api/NFTApiService";
import Header from "../Layout/Header";
import Loader from "../Layout/Loader";
import Wrapper from "../Layout/Wrapper";
import NFTMap from "../NFTMap/NFTMap";
import { MAX_ZOOM_REDUCED } from "../NFTMap/ZoomOptions";
import "./history.css";
import TimelineSlider from "./TimelineSlider";
import Footer from "../Layout/Footer";

/**
 * History page that shows a slider and an NFT Map that allows the
 * user to go through different versions of the NFT Map depending
 * on the selected index (or point in time).
 * Fetches data for all timelines from the back-end and displays
 * it on a smaller NFT Map to improve performance.
 * @component
 */
const History = () => {
  const [historyData, setHistoryData] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [nftData, setNftData] = useState(undefined);

  useEffect(() => {
    NFTApiService.GetHistoryData().then(data => {
      setHistoryData(data);

      setSelectedIndex(data.timeline.length - 1); // Last date first
    });
  }, []);

  useEffect(() => {
    if (!historyData)
      return;

    NFTApiService.GetHistoricalNFTData(historyData.timeline[selectedIndex].data).then(nftData => {
      setNftData(nftData);
    });
  }, [selectedIndex, historyData]);

  return <Wrapper>
    <Header />
    {(historyData && (
      <TimelineSlider
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        selectedTimeline={historyData.timeline[selectedIndex]}
        totalIndices={historyData.timeline.length}
      />
    ))}
    {(historyData && nftData) && (
      <NFTMap
        mapData={nftData}
        imageParts={[historyData.timeline[selectedIndex].img]}
        columnCount={historyData.divX}
        rowCount={historyData.divY}
        maxZoomLevel={MAX_ZOOM_REDUCED}
        isSingleView={true}
      />
    )}
    {(!historyData || !nftData) && <Loader />}
    <Footer />
  </Wrapper>;
};

export default History;
