import { useEffect, useState } from "react";
import NFTMap from "../NFTMap/NFTMap";
import { MAX_ZOOM_REDUCED } from "../NFTMap/ZoomOptions";
import "./buy.css";
import Button from "../Layout/Button";
import { useNavigate } from "react-router-dom";
import Modal from "../Layout/Modal";
import exclamationMark from "../../images/exclamation-mark.svg";

/**
 * By default, the Buy unavailable page displays a popup with information
 * when the buy page will be activated.
 * By clicking outside the popup, a blurred nft map appears, with an option to
 * view the mint field, which navigates to the mint page,
 * as well as fields that are already taken and by navigating to their links
 * @component
 */
const BuyUnavailable = ({
  nftData
}) => {
  const navigate = useNavigate();
  const [showBuyModal, setShowBuyModal] = useState(false);

  useEffect(() => {
    setShowBuyModal(true);
  }, [nftData]);
  
  return (
    <div className="no-buy-page">
      <Modal
        show={showBuyModal}
        handleClose={setShowBuyModal}
        header={<img src={exclamationMark} alt="no-buy" height={"150px"} />}
        body={<p>This page will be added after all NTFs are minted.</p>}
        footer={
          <Button
            buttonText={"Go to mint page"}
            onClick={() => navigate("/mint")}
          />
        }
      />
      <NFTMap
        mapData={nftData?.mapData}
        imageParts={[nftData.img]}
        columnCount={nftData.divX}
        rowCount={nftData.divY}
        maxZoomLevel={MAX_ZOOM_REDUCED}
        isSingleView={true}
      />
    </div>
  );
};
export default BuyUnavailable;



