const { 
  REACT_APP_API_URL,
} = process.env;

export class ApiConfig {

  constructor() {
    this.apiUrl = REACT_APP_API_URL;
  }
}

const config = new ApiConfig();

export default config;
