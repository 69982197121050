import NFTApiService from "../../api/NFTApiService";
import NFTMap from "../NFTMap/NFTMap";
import {
  MAX_ZOOM_REDUCED,
} from "../NFTMap/ZoomOptions";
import "./buy.css";
import { useState, useEffect, useContext } from "react";
import NFTInfo from "./NFTInfo";
import { WalletContext } from "../../context/WalletContext";

/**
 * Buy page that shows the NFT Map alongside a flow
 * that lets the user purchase/trade a selected NFT.
 * @component
 */
const BuyAvailable = ({
  setShowSuccessModal,
  nftData
}) => {
  const [buyData, setBuyData] = useState();
  const [nftClicked, setNftClicked] = useState(false);
  const [bottomDrawer, openBottomDrawer] = useState(true);
  const [userNft, setUserNft] = useState();
  const { account, showWallet } = useContext(WalletContext);

  useEffect(() => {
    if (showWallet && account) {
      NFTApiService.GetManageData(account.substring(2)).then((data) =>
        setUserNft(data.nfts)
      );
    }
  }, [showWallet, account]);

  const onClickNFT = (event) => {
    NFTApiService.GetBuyDataId(event?.id).then((data) => {
      setBuyData(data);
      setNftClicked(true);
      openBottomDrawer(true);
    });
  };

  return (
    <>
      <div className="buy-page">
        <NFTMap
          mapData={nftData.mapData}
          imageParts={[nftData.img]}
          columnCount={nftData.divX}
          rowCount={nftData.divY}
          maxZoomLevel={MAX_ZOOM_REDUCED}
          isSingleView={true}
          isBuyView={true}
          onClick={onClickNFT}
          disableClickVerification={true}
        />
        {bottomDrawer ? (
          <NFTInfo
            nftClicked={nftClicked}
            buyData={buyData}
            openBottomDrawer={openBottomDrawer}
            bottomDrawer={bottomDrawer}
            setShowSuccessModal={setShowSuccessModal}
            userNft={userNft}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default BuyAvailable;
