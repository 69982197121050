import PropTypes from "prop-types";
import { useWindowSize } from "../../helpers/common";

export const MAX_ZOOM = 8;
export const MAX_ZOOM_REDUCED = 4;
export const MIN_ZOOM = 1;
export const ZOOM_CHANGE = 1/10;
export const ZOOM_CHANGE_BTN = ZOOM_CHANGE * 5;

export const XS_ZOOM = 3;
export const MD_ZOOM = 2;
export const DEFAULT_ZOOM = 1;

/**
 * Represents a container with options for zooming in and out.
 * 
 * @component
 * @param {func} onZoom - Zoom function triggered on zoom button click.
 */
const ZoomOptions = ({ onZoom }) => {
  const windowSize = useWindowSize();

  // Hide zoom buttons on smaller screens.
  if (windowSize.width < 1000) {
    return;
  }

  return <div className="zoom-options">
    <button 
      className="zoom-button"
      onClick={() => onZoom(-1)}
      title="Zoom in"
    >
      +
    </button>
    <button
      className="zoom-button"
      onClick={() => onZoom(+1)}
      title="Zoom out"
    >
      -
    </button>
  </div>
}

ZoomOptions.propTypes = {
  onZoom: PropTypes.func.isRequired,
};

export default ZoomOptions;
