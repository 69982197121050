import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import "./footerPage.css";

/**
 * Impressum component is part of the footer.
 * The component contains textual informations
 * and logo which navigates to homepage.
 * @component
 */

const Imprint = () => {
  return (
    <Wrapper>
      <Header />
      <div className="textual-page">
        <h1>Imprint</h1>
        <p>Billionmatic is a product of Bavarian Crpyto Labs GmbH.</p>
        <p>
          Responsible for content according to § 5 TMG <br />
          Bavarian Crypto Labs GmbH <br />
          Am Hirschbach 17, 89355 Gundremmingen, Germany
        </p>
        <p>
          Represented by the Managing Director: <br />
          Lion Harisch <br />
          E-mail: lion (at) bavariancryptolabs.com
        </p>
        <p>
          Registered in the Commercial Register: <br />
          Register Court: Memmingen <br />
          Register Number: HRB 20240
        </p>
        <p>
          Responsible for content pursuant to § 55 para. 2 of the Interstate
          Broadcasting Treaty (RStV): Lion Harisch
        </p>
        <p>Notice according to the Online Dispute Settlement Regulation</p>
        <p>
          However, we expressly point out that we are not prepared to
          participate in the dispute resolution procedure within the framework
          of the European Online Dispute Resolution Platform.
        </p>
        <p>To contact us, please use our e-mail.</p>
        <p>
          The following imprint also applies to the following social media
          profiles
        </p>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default Imprint;
