import { useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import selector from "../../images/selector.svg";
import selectorUp from "../../images/selector-up.svg";

/**
 * Frequently asked questions page shown in footer.
 */
const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);

  const questions = [{
    question: "What is the billionmaticpage?",
    answer: "The billionmaticpage is a polygon NFT project where users can buy, sell, and customize NFTs. Those NFTs are displayed on the Billionmaticpage. Visitors can click on any NFT and are getting directed to the URL from the NFT owner.",
  }, {
    question: "How do I buy an NFT on the billionmaticpage?",
    answer: "To buy an NFT on the billionmaticpage, you will need to have a Browser wallet (e.g. MetaMask, TrustWallet) with some MATIC. Once you have that, you can browse the available NFTs on the website and purchase the one you would like to own.",
  },
  {
    question: "How do I sell my NFT on the billionmaticpage?",
    answer: "To sell your BillionMatic NFT, you will need to have it in your wallet. Once you have it, you can list it for sale and other users can purchase it.",
  },
  {
    question: "How do I customize my NFT on the billionmaticpage?",
    answer: "To customize your NFT on the billionmaticpage you can use the built-in customization tools to change its appearance.",
  },
  {
    question: "Is there a history page on the billionmaticpage?",
    answer: "Yes, there is a history page on the billionmaticpage where previous NFTs are displayed.",
  },
  {
    question: "What are the fees associated with buying, selling and customizing NFTs on the billionmaticpage?",
    answer: "There may be fees associated with buying, selling, and customizing NFTs on the billionmaticpage, such as transaction fees and gas fees. These fees are typically paid in MATIC and will vary depending on network congestion. It is recommended that you check the fee schedule on the billionmaticpage website before making any transactions.",
  },
  {
    question: "What is polygon?",
    answer: "Polygon is a layer 2 scaling solution for Ethereum. It allows for faster and cheaper transactions on Ethereum.",
  },
  {
    question: "How can I get MATIC?",
    answer: "You can get MATIC by purchasing it on an exchange, such as coinbase or binance, or by earning it through staking, farming or liquidity providing.",
  }];

const questionList = questions.map((question, idx) => (
  <div className="faq-row" key={`faq-${idx}`}>
    <div className="faq-question" onClick={() => {
      if (selectedQuestion === idx) {
        setSelectedQuestion(undefined);
      } else {
        setSelectedQuestion(idx);
      }
    }}>
      {question.question}
      <img alt="selector" src={(selectedQuestion === idx) ? selectorUp : selector} />
    </div>
    {selectedQuestion === idx && (
      <div className="faq-answer">{question.answer}</div>
    )}
  </div>
));

return <Wrapper>
  <Header />
  <div className="faq-body">
    <h1 className="faq-title">Frequently Asked Questions</h1>
    {questionList}
  </div>
  <Footer />
</Wrapper>
};

export default FAQ;