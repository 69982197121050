import ModalWrapper from "./ModalWrapper";
import PropTypes from "prop-types";
import foxImage from "../../images/fox.svg";
import Button from "./Button";

/**
 * Success modal for updating NFT metadata that describes
 * the change needing approval by Blockchain first.
 * 
 * @component
 * @param {bool} show - Whether to display the modal or not.
 * @param {func} handleClose - Function that handles toggling of states. 
 */
const ChangeApprovalModal = ({ show, handleClose }) => {
  return <ModalWrapper
    open={show}
    setOpen={handleClose}
  >
    <div className="alert-backdrop">
      <div className="alert">
        <div className="modal">
          <div className="modal-header">
            <div className="alert-header">
              <img
                src={foxImage}
                className="modal-image"
                alt="avatar"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="alert-body">
              <h2>Hang in there!</h2>
              <p>
                You will be able to see your changes in a minute or two.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <Button
                onClick={() => handleClose(false)}
                buttonText={"OK"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>;
};

ChangeApprovalModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeApprovalModal;
