import React, { useState } from "react";

/**
 * Number steps component is used in How it works page.
 * It contains all the steps marked with numbers from 1
 * to 6. The active one gets different color.
 * @component
 */
const NumberSteps = () => {
  const [activeCircle, setActiveCircle] = useState(1);

  const handleClick = (id) => {
    setActiveCircle(id);
  };

  return (
    <div className="column container">
      <div
        className={`circle ${activeCircle === 1 ? "active" : ""}`}
        data-id="1"
        onClick={() => handleClick(1)}
      >
        1
      </div>
      <div className="vertical-line"></div>
      <div
        className={`circle ${activeCircle === 2 ? "active" : ""}`}
        data-id="2"
        onClick={() => handleClick(2)}
      >
        2
      </div>
      <div className="vertical-line"></div>
      <div
        className={`circle ${activeCircle === 3 ? "active" : ""}`}
        data-id="3"
        onClick={() => handleClick(3)}
      >
        3
      </div>
      <div className="vertical-line"></div>
      <div
        className={`circle ${activeCircle === 4 ? "active" : ""}`}
        data-id="4"
        onClick={() => handleClick(4)}
      >
        4
      </div>
      <div className="vertical-line"></div>
      <div
        className={`circle ${activeCircle === 5 ? "active" : ""}`}
        data-id="5"
        onClick={() => handleClick(5)}
      >
        5
      </div>
      <div className="vertical-line"></div>
      <div
        className={`circle ${activeCircle === 6 ? "active" : ""}`}
        data-id="6"
        onClick={() => handleClick(6)}
      >
        6
      </div>
    </div>
  );
};

export default NumberSteps;
