import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import "./footerPage.css";

/**
 * Privacy Policy component is part of the footer.
 * The component contains textual informations
 * and logo which navigates to homepage.
 * @component
 */
const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Header />
      <div className="textual-page">
        <h1>Privacy Policy</h1>
        <h3>Introduction</h3>
        <p>
          With the following data protection declaration, we would like to
          inform you about the types of your personal data (hereinafter also
          referred to as "data") that we process, for what purposes and to what
          extent. The data protection declaration applies to all processing of
          personal data carried out by us, both as part of the provision of our
          services and in particular on our websites, in mobile applications and
          within external online presences, such as our social media profiles
          (hereinafter collectively referred to as "online offer").
          <br />
          The terms used are not gender-specific.
          <br />
          Status: 1 February 2023
        </p>
        <h3>Table of contents</h3>
        <li>Introduction</li>
        <li> Responsible</li>
        <li> Contact Data Protection Officer</li>
        <li>Overview of the processing</li>
        <li> Relevant legal bases</li>
        <li>Security measures</li>
        <li> Transmission of personal data</li>
        <li>Data deletion</li>
        <li> Business services</li>
        <li> Provision of the online offer and web hosting </li>
        <li> Presence in social networks (social media) </li>
        <li>Plugins and embedded functions and content </li>
        <li>Amendment and update of the privacy policy </li>
        <li>Rights of the data subjects</li>
        <li> Definitions of terms</li>
        <h3>Responsible</h3>
        <p>
          bavarian crypt Labs GmbH, Am Hirchbach 17, 89355 Gundremmingen
          <br />
          Authorised representatives:
          <br />
          Lion Harisch <br />
          E-mail address: <br />
          lion@harisch.ventures
        </p>
        <h3>Contact Data Protection Officer</h3>
        <a href="mailto:joerg@bavariancryptolabs.com ">
          joerg@bavariancryptolabs.com{" "}
        </a>
        <h3>Overview of the processing</h3>
        <div>
          The following overview summarises the types of data processed and the
          purposes of their processing and refers to the data subjects.
          <h4>Types of data processed</h4>
          <li> Inventory data.</li>
          <li>Payment details.</li>
          <li>Contact details.</li>
          <li>Content data.</li>
          <li>Contract data.</li>
          <li>Usage data.</li>
          <li>Meta, communication and procedural data.</li>
          <h4>Categories of persons concerned</h4>
          <li>Customers.</li>
          <li>Interested parties.</li>
          <li>Users.</li>
          <li>Business and contractual partners.</li>
          <h4>Purposes of the processing</h4>
          <li>Provision of contractual services and customer service.</li>
          <li>Contact requests and communication.</li>
          <li>Safety measures.</li>
          <li>Office and organisational procedures.</li>
          <li>Conversion measurement.</li>
          <li>Managing and responding to enquiries.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Profiles with user-related information.</li>
          <li>Provision of our online offer and user-friendliness.</li>
          <li>Information technology infrastructure.</li>
          <h4>Relevant legal bases</h4>
          <p>
            Below you will find an overview of the legal basis of the GDPR on
            the basis of which we process personal data. Please note that in
            addition to the provisions of the GDPR, national data protection
            regulations may apply in your or our country of residence or
            domicile. Should more specific legal bases be relevant in individual
            cases, we will inform you of these in the data protection
            declaration.
          </p>
          <li>
            <strong>
              Contract performance and pre-contractual enquiries (Art. 6 (1) p.
              1 lit. b) GDPR) -{" "}
            </strong>
            Processing is necessary for the performance of a contract to which
            the data subject is party or for the performance of pre-contractual
            measures taken at the data subject's request.
          </li>
          <li>
            <strong>
              Legal obligation (Art. 6 para. 1 p. 1 lit. c) GDPR) -{" "}
            </strong>
            Processing is necessary for compliance with a legal obligation to
            which the controller is subject.
          </li>
          <li>
            <strong>
              Legitimate interests (Art. 6 (1) p. 1 lit. f) GDPR) -{" "}
            </strong>
            Processing is necessary to protect the legitimate interests of the
            controller or a third party, unless such interests are overridden by
            the interests or fundamental rights and freedoms of the data subject
            which require the protection of personal data.
          </li>
          <p>
            In addition to the data protection regulations of the GDPR, national
            regulations on data protection apply in Germany. These include, in
            particular, the Act on Protection against Misuse of Personal Data in
            Data Processing (Federal Data Protection Act - BDSG). In particular,
            the BDSG contains special regulations on the right to information,
            the right to erasure, the right to object, the processing of special
            categories of personal data, the processing for other purposes and
            the transmission and automated decision-making in individual cases,
            including profiling. Furthermore, it regulates data processing for
            purposes of the employment relationship (Section 26 BDSG), in
            particular with regard to the establishment, implementation or
            termination of employment relationships as well as the consent of
            employees. Furthermore, data protection laws of the individual
            federal states may apply.
          </p>
        </div>
        <h3>Security measures</h3>
        <p>
          We take appropriate technical and organisational measures to ensure a
          level of protection appropriate to the risk in accordance with the
          legal requirements, taking into account the state of the art, the
          implementation costs and the nature, scope, circumstances and purposes
          of the processing as well as the different probabilities of occurrence
          and the extent of the threat to the rights and freedoms of natural
          persons.
          <br />
          The measures include, in particular, ensuring the confidentiality,
          integrity and availability of data by controlling physical and
          electronic access to the data, as well as access to, entry into,
          disclosure of, assurance of availability of and segregation of the
          data. We also have procedures in place to ensure the exercise of data
          subjects' rights, the deletion of data and responses to data
          compromise. Furthermore, we already take the protection of personal
          data into account in the development or selection of hardware,
          software and procedures in accordance with the principle of data
          protection, through technology design and through data
          protection-friendly default settings.
          <br />
          TLS encryption (https): In order to protect your data transmitted via
          our online offer, we use TLS encryption. You can recognise such
          encrypted connections by the prefix https:// in the address bar of
          your browser.
        </p>
        <h3>Transmission of personal data</h3>
        <p>
          In the course of our processing of personal data, the data may be
          transferred to or disclosed to other bodies, companies, legally
          independent organisational units or persons. The recipients of this
          data may include, for example, service providers commissioned with IT
          tasks or providers of services and content that are integrated into a
          website. In such cases, we observe the legal requirements and, in
          particular, conclude corresponding contracts or agreements that serve
          to protect your data with the recipients of your data.
          <br />
          Transfer of data within the organisation: We may transfer or provide
          access to personal data to other entities within our organisation.
          Where this transfer is for administrative purposes, the transfer of
          data is based on our legitimate corporate and business interests or is
          made where it is necessary for the performance of our contract-related
          obligations or where there is consent from the data subjects or legal
          permission.
        </p>
        <h3>Data deletion</h3>
        <p>
          The data processed by us will be deleted in accordance with the legal
          requirements as soon as their consents permitted for processing are
          revoked or other permissions cease to apply (e.g. if the purpose of
          processing this data has ceased to apply or it is not required for the
          purpose). If the data are not deleted because they are required for
          other and legally permissible purposes, their processing is limited to
          these purposes. I.e. the data is blocked and not processed for other
          purposes. This applies, for example, to data that must be retained for
          reasons of commercial or tax law or whose storage is necessary for the
          assertion, exercise or defence of legal claims or for the protection
          of the rights of another natural or legal person.
          <br />
          Our privacy notices may also contain further information on the
          retention and deletion of data, which will take precedence for the
          respective processing operations.
        </p>
        <h3>Business services</h3>
        <p>
          We process data of our contractual and business partners, e.g.
          customers and interested parties (collectively referred to as
          "contractual partners") in the context of contractual and comparable
          legal relationships as well as related measures and in the context of
          communication with contractual partners (or pre-contractual), e.g. to
          answer enquiries. We process this data in order to fulfil our
          contractual obligations. These include, in particular, the obligations
          to provide the agreed services, any update obligations and remedies in
          the event of warranty and other service disruptions. Furthermore, we
          process the data to safeguard our rights and for the purpose of the
          administrative tasks associated with these obligations as well as the
          company organisation. Furthermore, we process the data on the basis of
          our legitimate interests in proper and business management as well as
          security measures to protect our contractual partners and our business
          operations from misuse, endangerment of their data, secrets,
          information and rights (e.g. for the involvement of
          telecommunications, transport and other auxiliary services as well as
          subcontractors, banks, tax and legal advisors, payment service
          providers or tax authorities). Within the framework of applicable law,
          we only disclose the data of contractual partners to third parties to
          the extent that this is necessary for the aforementioned purposes or
          to fulfil legal obligations. Contractual partners will be informed
          about further forms of processing, e.g. for marketing purposes, within
          the framework of this data protection declaration.
          <br />
          We inform the contractual partners of the data required for the
          above-mentioned purposes before or in the course of data collection,
          e.g. in online forms, by means of special labelling (e.g. colours) or
          symbols (e.g. asterisks or similar), or in person. We delete the data
          after the expiry of statutory warranty and comparable obligations,
          i.e., in principle after 4 years, unless the data is stored in a
          customer account, e.g., as long as it must be retained for legal
          archiving reasons. The statutory retention period for documents
          relevant under tax law as well as for commercial books, inventories,
          opening balances, annual financial statements, the work instructions
          required to understand these documents and other organisational
          documents and accounting vouchers is ten years and for received
          commercial and business letters and reproductions of sent commercial
          and business letters six years. The period shall begin at the end of
          the calendar year in which the last entry was made in the book, the
          inventory, the opening balance sheet, the annual accounts or the
          management report was drawn up, the commercial or business letter was
          received or sent or the accounting document was created, furthermore
          the recording was made or the other documents were created.
          <br />
          Insofar as we use third-party providers or platforms to provide our
          services, the terms and conditions and data protection notices of the
          respective third-party providers or platforms apply in the
          relationship between the users and the providers.
          <li>
            <strong>Types of data processed: </strong>
            inventory data (e.g. names, addresses); payment data (e.g. bank
            details, invoices, payment history); contact data (e.g. e-mail,
            telephone numbers); contract data (e.g. subject matter of contract,
            term, customer category); usage data (e.g. websites visited,
            interest in content, access times); meta, communication and
            procedural data (e.g. IP addresses, time data, identification
            numbers, consent status).
          </li>
          <li>
            <strong>Affected persons: </strong>
            Customers; interested parties; business and contractual partners.
          </li>
          <li>
            <strong>Purposes of processing: </strong>
            provision of contractual services and customer service; security
            measures; contact requests and communication; office and
            organisational procedures; managing and responding to requests;
            conversion measurement (measuring the effectiveness of marketing
            measures); profiles with user-related information (creating user
            profiles).
          </li>
          <li>
            <strong>Legal basis: </strong>
            Contract performance and pre-contractual enquiries (Art. 6 para. 1
            p. 1 lit. b) GDPR); Legal obligation (Art. 6 para. 1 p. 1 lit. c)
            GDPR); Legitimate interests (Art. 6 para. 1 p. 1 lit. f) GDPR).
          </li>
        </p>
        <p>
          <strong>
            Further guidance on processing operations, procedures and services:
          </strong>
          <li>
            <strong>Economic analyses and market research: </strong>
            For economic reasons and in order to be able to recognise market
            trends, wishes of contractual partners and users, we analyse the
            data we have on business transactions, contracts, enquiries, etc.,
            whereby the group of persons concerned may include contractual
            partners, interested parties, customers, visitors and users of our
            online offer.The analyses are carried out for the purpose of
            economic evaluations, marketing and market research (e.g. to
            determine customer groups with different characteristics). In doing
            so, we may, if available, take into account the profiles of
            registered users together with their details, e.g. on services used.
            The analyses serve us alone and are not disclosed externally, unless
            they are anonymous analyses with summarised, i.e. anonymised values.
            Furthermore, we take into account the privacy of the users and
            process the data for the analysis purposes as pseudonymously as
            possible and, if feasible, anonymously (e.g. as summarised data);
            <strong>legal basis: </strong>legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR).
          </li>
          <li>
            <strong>Shop and e-commerce: </strong>
            We process the data of our customers in order to enable them to
            select, purchase or order the selected products, goods and
            associated services, as well as their payment and delivery or
            execution. If necessary for the execution of an order, we use
            service providers, in particular postal, forwarding and shipping
            companies, to carry out the delivery or execution for our customers.
            For the processing of payment transactions, we use the services of
            banks and payment service providers. The required information is
            identified as such in the context of the order or comparable
            purchase process and includes the information required for delivery
            or provision and billing as well as contact information in order to
            be able to contact you; <strong>legal basis: </strong>contract
            performance and pre-contractual enquiries (Art. 6 para. 1 p. 1 lit.
            b) GDPR).
          </li>
        </p>{" "}
        <h3>Provision of the online offer and web hosting</h3>
        <p>
          We process users' data in order to provide them with our online
          services. For this purpose, we process the user's IP address, which is
          necessary to transmit the content and functions of our online services
          to the user's browser or terminal device.
          <li>
            <strong>Types of data processed: </strong>
            Usage data (e.g. web pages visited, interest in content, access
            times); meta, communication and procedural data (e.g. IP addresses,
            time data, identification numbers, consent status).
          </li>
          <li>
            <strong>Data subjects: </strong>
            Users (e.g. website visitors, users of online services).
          </li>
          <li>
            <strong>Purposes of processing: </strong> provision of our online
            offer and user-friendliness; information technology infrastructure
            (operation and provision of information systems and technical
            devices (computers, servers, etc.).); security measures.
          </li>
          <li>
            <strong> Legal basis: </strong> Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR).
          </li>
          <strong>
            Further guidance on processing operations, procedures and services:
          </strong>
          <li>
            <strong>Provision of online offer on rented storage space: </strong>
            For the provision of our online offer, we use storage space,
            computing capacity and software that we rent or otherwise obtain
            from a corresponding server provider (also called "web hoster");
            <strong> Legal basis: </strong> Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR).
          </li>{" "}
          <li>
            <strong>Collection of access data and log files: </strong>
            Access to our online offer is logged in the form of so-called
            "server log files". The server log files may include the address and
            name of the web pages and files accessed, the date and time of
            access, the volume of data transferred, notification of successful
            access, browser type and version, the user's operating system,
            referrer URL (the previously visited page) and, as a rule, IP
            addresses and the requesting provider.The server log files may be
            used for security purposes, for example, to prevent server overload
            (especially in the event of abusive attacks, so-called DDoS attacks)
            and, on the other hand, to ensure server utilisation and stability;
            <strong>legal basis: </strong> legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR);
            <strong>deletion of data: </strong> Log file information is stored
            for a maximum of 30 days and then deleted or anonymised. Data whose
            further storage is necessary for evidentiary purposes is exempt from
            deletion until the final clarification of the respective incident.
          </li>
        </p>
        <h3>Presence in social networks (social media)</h3>
        <p>
          We maintain online presences within social networks and process user
          data in this context in order to communicate with users active there
          or to offer information about us.
          <br />
          We would like to point out that user data may be processed outside the
          European Union.
          <br />
          This may result in risks for the users because, for example, it could
          make it more difficult to enforce the rights of the users.
          <br />
          Furthermore, user data within social networks is usually processed for
          market research and advertising purposes. For example, usage profiles
          can be created based on the usage behaviour and resulting interests of
          the users. The usage profiles can in turn be used, for example, to
          place advertisements within and outside the networks that presumably
          correspond to the interests of the users. For these purposes, cookies
          are usually stored on the users' computers, in which the usage
          behaviour and the interests of the users are stored. Furthermore, data
          independent of the devices used by the users may also be stored in the
          usage profiles (especially if the users are members of the respective
          platforms and are logged in to them).
          <br />
          For a detailed presentation of the respective forms of processing and
          the options to object (opt-out), we refer to the data protection
          declarations and information provided by the operators of the
          respective networks. <br />
          In the case of requests for information and the assertion of data
          subject rights, we would also like to point out that these can be
          asserted most effectively with the providers. Only the providers have
          access to the users' data and can take appropriate measures and
          provide information directly. If you still need help, you can contact
          us.
          <li>
            <strong>Types of data processed: </strong>
            contact data (e.g. e-mail, telephone numbers); content data (e.g.
            entries in online forms); usage data (e.g. websites visited,
            interest in content, access times); meta, communication and process
            data (e.g. IP addresses, time data, identification numbers, consent
            status).
          </li>
          <li>
            <strong>Data subjects: </strong>
            Users (e.g. website visitors, users of online services).
          </li>
          <li>
            <strong>Purposes of processing: </strong>
            contact requests and communication; feedback (e.g. collecting
            feedback via online form); marketing.
          </li>
          <li>
            <strong>Legal basis: </strong>
            Legitimate interests (Art. 6 para. 1 p. 1 lit. f) GDPR).
          </li>
          <strong>
            Further guidance on processing operations, procedures and services:
          </strong>
          <li>
            <strong>Instagram: </strong>
            Social network;
            <strong> Service provider: </strong> Meta Platforms Ireland Limited,
            4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland;
            <strong> Legal basis: </strong>Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR);
            <strong> Website: </strong>{" "}
            <a href="https://www.instagram.com"> https://www.instagram.com </a>;
            <strong> Privacy policy: </strong>
            <a href=" https://instagram.com/about/legal/privacy">
              https://instagram.com/about/legal/privacy.
            </a>
          </li>
          <li>
            <strong>Facebook pages: </strong>
            Profiles within the Facebook social network - We are jointly
            responsible with Meta Platforms Ireland Limited for the collection
            (but not the further processing) of data from visitors to our
            Facebook page (known as a "Fan Page"). This data includes
            information about the types of content users view or interact with,
            or the actions they take (see under "Things You and Others Do and
            Provide" in the Facebook Data Policy:{" "}
            <a href="https://www.facebook.com/policy">
              https://www.facebook.com/policy
            </a>
            ), as well as information about the devices users use (e.g. IP
            addresses, operating system, browser type, language settings, cookie
            data; see under "Device Information" in the Facebook Data Policy:{" "}
            <a href="https://www.facebook.com/policy">
              https://www.facebook.com/policy
            </a>
            ). As explained in the Facebook Data Policy under "How do we use
            this information?", Facebook also collects and uses information to
            provide analytics services, called "Page Insights", to Page
            operators to provide them with insights into how people interact
            with their Pages and with the content associated with them. We have
            entered into a special agreement with Facebook ("Page Insights
            Information",{" "}
            <a href="https://www.facebook.com/legal/terms/page_controller_addendum">
               https://www.facebook.com/legal/terms/page_controller_addendum
            </a>
            ), which in particular regulates which security measures Facebook
            must observe and in which Facebook has agreed to fulfil the data
            subject rights (i.e. users can, for example, send information or
            deletion requests directly to Facebook). The rights of users (in
            particular to information, deletion, objection and complaint to the
            competent supervisory authority) are not restricted by the
            agreements with Facebook. Further information can be found in the
            "Information on Page Insights" (
            <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            );
            <strong> service provider: </strong> Meta Platforms Ireland Limited,
            4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland;
            <strong> Legal basis: </strong>
            Legitimate interests (Art. 6 para. 1 p. 1 lit. f) GDPR);
            <strong> Website: </strong>
            <a href="https://www.facebook.com">https://www.facebook.com</a>;
            <strong> Privacy policy: </strong>
            <a href="https://www.facebook.com/about/privacy">
              https://www.facebook.com/about/privacy
            </a>
            ;
            <strong>
              {" "}
              Standard contractual clauses (ensuring level of data protection
              for processing in third countries):{" "}
            </strong>
            <a href="https://www.facebook.com/legal/EU_data_transfer_addendum">
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>
            ;<strong> Further information: Shared </strong>responsibility
            agreement:{" "}
            <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
              https://www.facebook.com/legal/terms/information_about_page_insights_data
            </a>
            . Joint accountability is limited to the collection by and transfer
            of data to Meta Platforms Ireland Limited, a company based in the
            EU. Further processing of the data is the sole responsibility of
            Meta Platforms Ireland Limited, which concerns in particular the
            transfer of the data to the parent company Meta Platforms, Inc. in
            the USA (on the basis of the standard contractual clauses concluded
            between Meta Platforms Ireland Limited and Meta Platforms, Inc.)
          </li>
          <li>
            <strong>Twitter: </strong>
            social network;
            <strong> service provider: </strong>Twitter International Company,
            One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Ireland,
            parent company: Twitter Inc, 1355 Market Street, Suite 900, San
            Francisco, CA 94103, USA;
            <strong> Legal basis: </strong> Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR);
            <strong> Privacy policy: </strong>{" "}
            <a href="https://twitter.com/privacy">
              https://twitter.com/privacy
            </a>
            , (Settings:{" "}
            <a href="https://twitter.com/personalization">
              https://twitter.com/personalization
            </a>
            ).
          </li>
        </p>
        <h3>Plugins and embedded functions and content</h3>
        <p>
          We integrate functional and content elements into our online offer
          that are obtained from the servers of their respective providers
          (hereinafter referred to as "third-party providers"). These can be,
          for example, graphics, videos or city maps (hereinafter uniformly
          referred to as "content").
          <br />
          The integration always requires that the third-party providers of this
          content process the IP address of the user, as without the IP address
          they would not be able to send the content to their browser. The IP
          address is thus required for the display of this content or function.
          We endeavour to only use content whose respective providers only use
          the IP address to deliver the content. Third-party providers may also
          use so-called pixel tags (invisible graphics, also known as "web
          beacons") for statistical or marketing purposes. The "pixel tags" can
          be used to analyse information such as visitor traffic on the pages of
          this website. The pseudonymous information may also be stored in
          cookies on the user's device and may contain, among other things,
          technical information about the browser and operating system,
          referring websites, time of visit and other information about the use
          of our online offering, as well as being linked to such information
          from other sources.
          <li>
            <strong>Types of data processed: </strong>Usage data (e.g. websites
            visited, interest in content, access times); meta, communication and
            procedural data (e.g. IP addresses, time data, identification
            numbers, consent status); inventory data (e.g. names, addresses);
            contact data (e.g. e-mail, telephone numbers); content data (e.g.
            entries in online forms).
          </li>
          <li>
            <strong>Data subjects: </strong>Users (e.g. website visitors, users
            of online services).
          </li>
          <li>
            <strong>Purposes of the processing: </strong>Provision of our online
            offer and user-friendliness.
          </li>
          <li>
            <strong>Legal basis: </strong> Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR).
          </li>
          <strong>
            Further guidance on processing operations, procedures and services:
          </strong>
          <li>
            <strong>YouTube videos: </strong>
            Video content;
            <strong> Service provider: </strong>Google Ireland Limited, Gordon
            House, Barrow Street, Dublin 4, Ireland;
            <strong> Legal basis: </strong>Legitimate interests (Art. 6 para. 1
            p. 1 lit. f) GDPR);
            <strong> Website: </strong>{" "}
            <a href=" https://www.youtube.com"> https://www.youtube.com</a>;
            <strong> Privacy policy: </strong>{" "}
            <a href="https://policies.google.com/privacy">
              {" "}
              https://policies.google.com/privacy
            </a>
            ;<strong> Opt-out: </strong>Opt-Out-Plugin:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , Settings for the display of advertisements:{" "}
            <a href="https://adssettings.google.com/authenticated">
              https://adssettings.google.com/authenticated
            </a>
            .
          </li>
        </p>
        <h3>Amendment and update of the privacy policy</h3>
        <p>
          We ask you to regularly inform yourself about the content of our data
          protection declaration. We adapt the data protection declaration as
          soon as the changes in the data processing carried out by us make this
          necessary. We will inform you as soon as the changes require an act of
          cooperation on your part (e.g. consent) or other individual
          notification.
          <br />
          Where we provide addresses and contact details of companies and
          organisations in this privacy statement, please note that the
          addresses may change over time and please check the details before
          contacting us.
        </p>
        <h3>Rights of the data subjects</h3>
        <p>
          As a data subject, you are entitled to various rights under the GDPR,
          which arise in particular from Art. 15 to 21 GDPR:
          <li>
            <strong>
              Right of objection: You have the right to object at any time, on
              grounds relating to your particular situation, to the processing
              of personal data concerning you which is carried out on the basis
              of Article 6(1)(e) or (f) GDPR; this also applies to profiling
              based on these provisions. If the personal data concerning you are
              processed for the purpose of direct marketing, you have the right
              to object at any time to the processing of personal data
              concerning you for the purpose of such marketing; this also
              applies to profiling insofar as it is related to such direct
              marketing.
            </strong>
          </li>
          <li>
            <strong>Right of revocation for consents: </strong>You have the
            right to revoke any consent you have given at any time.
          </li>
          <li>
            <strong>Right of access: </strong> You have the right to request
            confirmation as to whether data in question is being processed and
            to be informed about this data and to receive further information
            and a copy of the data in accordance with the legal requirements.
          </li>
          <li>
            <strong>Right to rectification: In </strong>accordance with the law,
            you have the right to request that data concerning you be completed
            or that inaccurate data concerning you be rectified.
          </li>
          <li>
            <strong>Right to erasure and restriction of processing: </strong>You
            have the right, in accordance with the law, to request that data
            relating to you be erased immediately or, alternatively, to request
            restriction of the processing of the data in accordance with the
            law.
          </li>
          <li>
            <strong>Right to data portability: </strong>
            You have the right to receive data relating to you that you have
            provided to us in a structured, common and machine-readable format
            in accordance with the legal requirements or to request that it be
            transferred to another controller.
          </li>
          <li>
            <strong>Complaint to the supervisory authority: </strong>
            Without prejudice to any other administrative or judicial remedy,
            you have the right to lodge a complaint with a supervisory
            authority, in particular in the Member State of your habitual
            residence, place of work or place of the alleged infringement, if
            you consider that the processing of personal data concerning you
            infringes the requirements of the GDPR.
          </li>
        </p>
        <h3>Definitions of terms</h3>
        <p>
          This section provides you with an overview of the terms used in this
          privacy policy. Many of the terms are taken from the law and defined
          above all in Art. 4 of the GDPR. The legal definitions are binding.
          The following explanations, on the other hand, are primarily intended
          to help you understand them. The terms are sorted alphabetically.
          <li>
            <strong>Conversion measurement: </strong>
            Conversion measurement (also referred to as "visit action
            evaluation") is a procedure that can be used to determine the
            effectiveness of marketing measures. For this purpose, a cookie is
            usually stored on the user's devices within the websites on which
            the marketing measures take place and then retrieved again on the
            target website. For example, this allows us to track whether the ads
            we have placed on other websites have been successful.
          </li>
          <li>
            <strong>Personal data: </strong>
            "Personal data" means any information relating to an identified or
            identifiable natural person (hereinafter "data subject"); an
            identifiable natural person is one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier
            (e.g. cookie) or to one or more factors specific to the physical,
            physiological, genetic, mental, economic, cultural or social
            identity of that natural person.
          </li>
          <li>
            <strong>Profiles with user-related information: </strong>
            The processing of "profiles with user-related information", or
            "profiles" for short, includes any type of automated processing of
            personal data that consists of using these personal data to analyse,
            evaluate or predict certain personal aspects relating to a natural
            person (depending on the type of profiling, this may include
            different information concerning demographics, behaviour and
            interests, such as interaction with websites and their content,
            etc.) (e.g. interests in certain content or products, click
            behaviour on a website or location). Cookies and web beacons are
            often used for profiling purposes.
          </li>
          <li>
            <strong>Controller: </strong>
            the natural or legal person, public authority, agency or other body
            which alone or jointly with others determines the purposes and means
            of the processing of personal data.
          </li>
          <li>
            <strong>Processing: </strong>
            "Processing" means any operation or set of operations which is
            performed upon personal data, whether or not by automatic means. The
            term is broad and covers virtually any handling of data, be it
            collection, analysis, storage, transmission or erasure.
          </li>
        </p>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default PrivacyPolicy;
