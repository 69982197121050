import PropTypes from "prop-types";
import { useState } from "react";
import Button from "./Button";
import "./option_buttons.css";
import PopoverButtonList from "./PopoverButtonList";

/**
 * Options button (three dots) alongside the popover list of buttons
 * displayed once the options button is clicked.
 * List of buttons is passed to this component.
 * 
 * @component
 * @param {array} actionList - List of PopoverButtons to be used.
 */
const OptionsButton = ({ actionList }) => {
  const [showList, setShowList] = useState(false);

  return <div className="options-button-container">
    <Button
      className="options-button"
      buttonText="..."
      onClick={() => {
        if (actionList.length === 1) {
          actionList[0].onClick();
        } else {
          setShowList(!showList);
        }
      }}
    />
    <PopoverButtonList
      show={showList}
      setShow={setShowList}
      actionList={actionList}
    />
  </div>
};

OptionsButton.propTypes = {
  actionList: PropTypes.array.isRequired,
};

export default OptionsButton;