import ModalWrapper from "./ModalWrapper";
import PropTypes from "prop-types";
import successImage from "../../images/success.svg";

/**
 * Success modal popup that states that an NFT is successfuly purchased.
 * Used for minting and purchasing.
 * 
 * @component
 * @param {bool} show - Whether to display the modal or not.
 * @param {func} handleClose - Function that handles toggling of states. 
 */
const SuccessModal = ({ show, handleClose }) => {
  return <ModalWrapper
    open={show}
    setOpen={handleClose}
  >
    <div className="alert-backdrop">
      <div className="alert">
        <div className="modal">
          <div className="modal-header">
            <div className="alert-header">
              <img
                src={successImage}
                className="modal-image"
                alt="avatar"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="alert-body">
              <h2>Success!</h2>
              <p>
                You have successfully purchased your NFT!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>;
};

SuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SuccessModal;
