import Header from "../Layout/Header";
import Loader from "../Layout/Loader";
import Wrapper from "../Layout/Wrapper";
import { useContext, useEffect, useState } from "react";
import NFTApiService from "../../api/NFTApiService";
import { DEFAULT_ZOOM } from "../NFTMap/ZoomOptions";
import ConnectWalletModal from "../Layout/ConnectWalletModal";
import { WalletContext } from "../../context/WalletContext";
import "./buy.css";
import BuyUnavailable from "./BuyUnavailable";
import SuccessModal from "../Layout/SuccessModal";
import BuyAvailable from "./BuyAvailable";
import Footer from "../Layout/Footer";

/**
 * Check if an NFT has been minted and based on it
 * renders either a BuyAvailable or BuyUnavailable component.
 * @component
 */
const Buy = (props) => {
  const [nftData, setNftData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { showModal, setShowModal } = useContext(WalletContext);

  useEffect(() => {
    checkIfMinted();
  }, []);

  const checkIfMinted = () => {
    NFTApiService.GetBuyData().then((data) => {
      setNftData(data);
    });
  };

  return (
    <Wrapper>
      <ConnectWalletModal show={showModal} handleClose={setShowModal} />
      <SuccessModal show={showSuccessModal} handleClose={setShowSuccessModal} />
      <Header />
      {!!nftData ? (
        nftData.buyable ? (
          <BuyAvailable
            nftData={nftData}
            zoomLevel={zoomLevel}
            setZoomLevel={setZoomLevel}
            setShowSuccessModal={setShowSuccessModal}
          />
        ) : (
          <BuyUnavailable
            nftData={nftData}
          />
        )
      ) : (
        <Loader />
      )}
      <Footer />
    </Wrapper>
  );
};

export default Buy;
