import { NavLink } from "react-router-dom";
import "./footer.css";

/**
 * Standard non-mobile footer used on all pages.
 * @component
 */
const Footer = () => {
  const navLinkClasses = ({ isActive }) => (isActive ? "active" : "");

  return (
    <div className="footer-container">
      <div className="footer-links">
        <NavLink to="/imprint" className={navLinkClasses}>
          Imprint
        </NavLink>
        <NavLink to="/terms" className={navLinkClasses}>
          Terms
        </NavLink>
        <NavLink to="/about-us" className={navLinkClasses}>
          About us
        </NavLink>
        {/* <NavLink to="/faq" className={navLinkClasses}>
          FAQ
        </NavLink>
      */}
        <NavLink to="/how-it-works" className={navLinkClasses}>
          How it works
        </NavLink>
        <NavLink to="/privacy-policy" className={navLinkClasses}>
          Privacy Policy
        </NavLink>
        <NavLink to="/faq" className={navLinkClasses}>
          FAQ
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
