import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { debounce, useWindowSize } from "../../helpers/common.js";
import Loader from "../Layout/Loader.js";
import NFT from "./NFT.js";
import "./nftmap.css";
import NFTMapImage from "./NFTMapImage.js";
import {
  DEFAULT_ZOOM,
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_CHANGE,
  ZOOM_CHANGE_BTN,
} from "./ZoomOptions.js";
import classNames from "classnames";
import HomepageTitle from "../Landing/HomepageTitle.js";

/**
 * Represents the overall NFTMap that holds the visible
 * background image of all the NFTs together and the
 * invisible layer of individual NFTs that are clickable.
 *
 * This component handles zooming and scrolling, as well.
 *
 * @component
 * @param {array} mapData - Array of NFT objects that need to be individually functional.
 * @param {array} imageParts - Array of image URLs to obtain the entire visible NFT map.
 * @param {number} columnCount - Number of columns, i.e. maximum NFT count in X direction for width of 1.
 * @param {number} rowCount - Number of rows, i.e. maximum NFT count in Y direction for height of 1.
 * @param {number} zoomLevel - Background image size multiplier having a range of [MIN_ZOOM, maxZoomLevel || MAX_ZOOM].
 * @param {func} setZoomLevel - Sets the zoom level while respecting range.
 * @param {number} maxZoomLevel - Sets maximum zoomed-in level.
 * @param {bool} isSingleView - Displays one map (history) or multiple maps combined into one (home).
 * @param {bool} isMintView - Whether to adjust the map to prioritize mintable plots.
 * @param {func} onClick - a function to be called when an NFT is clicked on the map in order to get info about specific NFT.
 * @param {boolean} disableClickVerification - If disabled then does not check NFT data but immediately calls onClick.
 * @param {bool} isBuyView - Whether to adjust the map to prioritize nfts that can be bought.
 * @param {bool} showZoomHeader - Shows the homepage header with zoom buttons on it.
 */
const NFTMap = ({
  mapData,
  imageParts,
  columnCount,
  rowCount,
  maxZoomLevel,
  isSingleView,
  isMintView,
  onClick,
  disableClickVerification,
  isBuyView,
  showZoomHeader,
}) => {
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0, dist: 0 });
  const [currentScroll, setCurrentScroll] = useState({ left: 0, top: 0 });
  const [nftList, setNftList] = useState([]);
  const [blurredRows, setBlurredRows] = useState([]);
  const nftMap = useRef();
  const nftMapImage = useRef();
  const [minZoomLevel, setMinZoomLevel] = useState(MIN_ZOOM);
  const mouseState = useRef({ down: false, drag: false });
  const [centerTranslate, setCenterTranslate] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const onInteractionStart = (event) => {
    mouseState.current.down = true;

    let dist = 0;
    if (event.touches && event.touches.length >= 2) {
      dist = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );
    }

    const pageX = event.pageX || event.touches[0].pageX;
    const pageY = event.pageY || event.touches[0].pageY;
    const startX = pageX - nftMap.current.offsetLeft;
    const startY = pageY - nftMap.current.offsetTop;

    setStartPosition({ x: startX, y: startY, dist });
    setCurrentScroll({
      left: nftMap.current.scrollLeft,
      top: nftMap.current.scrollTop,
    });
  };

  const onInteractionEnd = (e) => {
    mouseState.current.down = false;

    setTimeout(() => {
      mouseState.current.drag = false;
    }, 100);
  };

  // Zoom into center on zoom button click
  const onZoom = (sign) => {
    let newZoomLevel = zoomLevel;
    if (sign < 0) {
      newZoomLevel = Math.min(zoomLevel + ZOOM_CHANGE_BTN, maxZoomLevel || MAX_ZOOM);
    } else if (sign > 0) {
      newZoomLevel = Math.max(zoomLevel - ZOOM_CHANGE_BTN, minZoomLevel || MIN_ZOOM);
    }

    const centerX = nftMap.current.offsetWidth / 2 - nftMap.current.offsetLeft;
    const centerY = nftMap.current.offsetHeight / 2 - nftMap.current.offsetTop;

    nftMap.current.scrollLeft = (nftMap.current.scrollLeft + centerX) / zoomLevel * newZoomLevel - centerX;
    nftMap.current.scrollTop = (nftMap.current.scrollTop + centerY) / zoomLevel * newZoomLevel - centerY;

    setZoomLevel(newZoomLevel);
  };

  // Zoom into mouse cursor position when using wheel zoom
  const onWheelInteraction = debounce((e) => {
    if (!setZoomLevel) return;

    let newZoomLevel = zoomLevel;
    if (e.deltaY < 0) {
      newZoomLevel = Math.min(zoomLevel + ZOOM_CHANGE, maxZoomLevel || MAX_ZOOM);
    } else if (e.deltaY > 0) {
      newZoomLevel = Math.max(zoomLevel - ZOOM_CHANGE, minZoomLevel || MIN_ZOOM);
    }

    const mouseX = e.pageX - nftMap.current.offsetLeft - centerTranslate.x;
    const mouseY = e.pageY - nftMap.current.offsetTop - centerTranslate.y;

    // Scroll into mouse position.
    nftMap.current.scrollLeft = (nftMap.current.scrollLeft + mouseX) / zoomLevel * newZoomLevel - mouseX;
    nftMap.current.scrollTop = (nftMap.current.scrollTop + mouseY) / zoomLevel * newZoomLevel - mouseY;

    setZoomLevel(newZoomLevel);
  }, 1);

  const onInteraction = debounce((event) => {
    if (!mouseState.current.down) return;

    mouseState.current.drag = true;

    if (event.touches && event.touches.length >= 2 && setZoomLevel) {
      // Two touches used for zooming in and out.
      const dist = Math.hypot(
        event.touches[0].pageX - event.touches[1].pageX,
        event.touches[0].pageY - event.touches[1].pageY
      );
      const deltaDist = startPosition.dist - dist;

      let newZoomLevel = zoomLevel;
      if (deltaDist < 0) {
        newZoomLevel = Math.min(zoomLevel + ZOOM_CHANGE, maxZoomLevel || MAX_ZOOM);
      } else if (deltaDist > 0) {
        newZoomLevel = Math.max(zoomLevel - ZOOM_CHANGE, minZoomLevel || MIN_ZOOM);
      }

      // Scroll into mouse position.
      nftMap.current.scrollLeft = (nftMap.current.scrollLeft) / zoomLevel * newZoomLevel;
      nftMap.current.scrollTop = (nftMap.current.scrollTop) / zoomLevel * newZoomLevel;

      setZoomLevel(newZoomLevel);
    } else {
      // Otherwise scrolling
      const pageX = event.pageX || event.touches[0].pageX;
      const pageY = event.pageY || event.touches[0].pageY;

      const scrollX = pageX - nftMap.current.offsetLeft - startPosition.x;
      const scrollY = pageY - nftMap.current.offsetTop - startPosition.y;
      nftMap.current.scrollLeft = currentScroll.left - scrollX;
      nftMap.current.scrollTop = currentScroll.top - scrollY;
    }
  }, 0);

  // Run only once on load.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const onNFTClick = (event, nftData) => {
      // Prevent click on drag.
      if (mouseState.current.drag) return;

      // Links cannot be openned in Mint view.
      if (isMintView) {
        return;
      }

      // Empty plots (no ID) navigate to Mint page (when not on Mint page).
      if (typeof nftData.id === "undefined" && !isSingleView) {
        navigate("/mint");
        return;
      }

      // Unused taken plots (ID, but no URL) are unclickable.
      if (typeof nftData.url === "undefined") {
        return;
      }

      window.open(nftData.url, "_blank");
    };

    const nftGrid = Array(rowCount).fill().map(() => Array(columnCount).fill(0));

    setNftList(
      mapData.map((nftData, idx) => {
        let parsedNftData = nftData;
        if (Array.isArray(nftData)) {
          const [x, y, w, h, name, url] = nftData;
          parsedNftData = { x, y, w, h, name, url };
        }

        const { x, y, w, h } = parsedNftData;
        for (let yy = y; yy < y + h; yy++) {
          for (let xx = x; xx < x + w; xx++) {
            nftGrid[yy][xx] = 1;
          }
        }

        return (
          <NFT
            key={`nft-${idx}`}
            {...parsedNftData}
            widthPerc={(1.0 / columnCount) * 100}
            heightPerc={(1.0 / rowCount) * 100}
            onClick={(e) => {
              disableClickVerification
                ? onClick(parsedNftData)
                : onNFTClick(e, parsedNftData);
            }}
            isDisabled={isMintView && typeof nftData.id !== "undefined"}
            showAdditionalInfo={!isSingleView && !isMintView}
            showAsMintable={isMintView && typeof nftData.id === "undefined"}
          />
        );
      })
    );

    if (isBuyView || isMintView) {
      const rows = [];

      const widthPerc = (1.0 / columnCount) * 100;
      const heightPerc = (1.0 / rowCount) * 100;

      for (let yy = 0; yy < rowCount; yy++) {
        for (let xx = 0, gapX = 1; xx < columnCount; xx += gapX, gapX = 1) {
          if (nftGrid[yy][xx]) {
            continue;
          }

          // Go as far on the X axis and increment gapX.
          for (; xx + gapX < columnCount; gapX++) {
            if (nftGrid[yy][xx + gapX]) {
              break;
            }
          }

          rows.push(
            <div
              key={`blurred-row-${yy}-${xx}`}
              className="blurred-row"
              style={{
                left: `calc(${xx}% * ${widthPerc})`,
                top: `calc(${yy}% * ${heightPerc})`,
                width: `calc(${gapX}% * ${widthPerc})`,
                height: `calc(${1}% * ${heightPerc})`,
              }}
            />
          );
        }
      }

      setBlurredRows(rows);
    }
  }, []);

  useEffect(() => {
    const widthScale = nftMap.current.offsetWidth / nftMapImage.current.offsetWidth;
    const heightScale = nftMap.current.offsetHeight / nftMapImage.current.offsetHeight;

    const minZoom = Math.min(widthScale, heightScale);
    setMinZoomLevel(minZoom);
    setZoomLevel(minZoom);

    const translateX = (nftMap.current?.offsetWidth - nftMapImage.current?.offsetWidth * minZoom) / 2;
    const translateY = (nftMap.current?.offsetHeight - nftMapImage.current?.offsetHeight * minZoom) / 2;
    setCenterTranslate({ x: translateX, y: translateY });
  }, [hasLoaded, setZoomLevel, setMinZoomLevel]);

  return (
    <>
      {showZoomHeader && windowSize.width > 600 && (
        <HomepageTitle
          onZoom={onZoom}
        />
      )}
      <div
        ref={nftMap}
        className="nft-map-container"
        onWheel={onWheelInteraction}
        onKeyUp={onInteraction}
        onMouseMove={onInteraction}
        onMouseDown={onInteractionStart}
        onMouseUp={onInteractionEnd}
        onMouseLeave={onInteractionEnd}
        onTouchStart={onInteractionStart}
        onTouchMove={onInteraction}
        onTouchEnd={onInteractionEnd}
      >
        {!hasLoaded && <Loader />}
        <div
          ref={nftMapImage}
          className="nft-map"
          style={{
            transform: `translate(${centerTranslate.x}px, ${centerTranslate.y}px) scale(${zoomLevel})`,
          }}
        >
          <NFTMapImage
            imageParts={imageParts}
            zoomLevel={zoomLevel}
            isSingleMap={isSingleView}
            onLoad={() => {
              setHasLoaded(true);
            }}
          />

          {(isBuyView || isMintView) && (
            <div className="blurred-rows">
              {blurredRows}
            </div>
          )}

          <div className={classNames("nft-list", { hidden: !hasLoaded })}>
            {nftList}
          </div>
        </div>
      </div>
    </>
  );
};

NFTMap.propTypes = {
  mapData: PropTypes.array.isRequired,
  imageParts: PropTypes.array.isRequired,
  columnCount: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  maxZoomLevel: PropTypes.number,
  isSingleView: PropTypes.bool,
  isMintView: PropTypes.bool,
  onClick: PropTypes.func,
  disableClickVerification: PropTypes.bool,
  isBuyView: PropTypes.bool,
  showZoomHeader: PropTypes.bool,
};

export default NFTMap;
