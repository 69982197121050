import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Buy from "../components/Buy/Buy";
import AboutUs from "../components/Footer/AboutUs";
import FAQ from "../components/Footer/FAQ";
import HowItWorks from "../components/Footer/HowItWorks";
import Imprint from "../components/Footer/Imprint";
import PrivacyPolicy from "../components/Footer/PrivacyPolicy";
import Terms from "../components/Footer/Terms";
import History from "../components/History/History";
import Homepage from "../components/Landing/Homepage";
import Manage from "../components/Manage/Manage";
import Mint from "../components/Mint/Mint";

const Routing = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/history" element={<History />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/faq" element={<FAQ />}/>
        </Routes>
      </Router>
    </>
  );
};

export default Routing;
