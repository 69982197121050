import createWalletImage from "../../images/createWallet.svg";
import purchaseNFTImage from "../../images/purchaseNFT.svg";
import listNFTImage from "../../images/listNFT.svg";

/**
 * First Column component is used in How it works page.
 * It contains all the text from the left side of screen
 * on the web version.
 * @component
 */
const FirstColumn = () => {
  return (
    <div className="column hiw-page">
      <h1>Create a Wallet</h1>
      <p>
        To use the Billionmaticpage, you will need to have a MATIC/Polygon wallet, which you can install within the chrome browser.
        This wallet will be used to store and manage your NFTs. You can use for example MetaMask or TrustWallet.
      </p>
      <img src={createWalletImage} alt="create-wallet" />
      <h1>Purchase an NFT</h1>
      <p>
        To purchase an NFT, you will need to have some MATIC in your wallet.
        Once you have found an NFT you would like to own, simply navigate to the "buy" section, click on the "buy" button and confirm the transaction in your wallet.
      </p>
      <img src={purchaseNFTImage} alt="purchase-nft" />
      <h1>List your NFT for sale</h1>
      <p>
        If you would like to sell your NFT, you can list it for sale on the Billionmaticpage.
        Other users can then browse and purchase your NFT.
      </p>
      <img src={listNFTImage} alt="list-nft" />
    </div>
  );
};
export default FirstColumn;
