import React from "react";
import PropTypes from "prop-types";
import "./button.css";

/**
 * Custom Button class that encapsulates the plain button element.
 * Uses a predefined CSS class and provides input for style in CSS dimensions.
 * 
 * @component
 * @param {string} className - Class name to use other than predefined.
 * @param {string} width - Width given, ex. "90px".
 * @param {string} height - Height given for the button.
 * @param {string} fontSize - Font size of the button.
 * @param {string} buttonText - Text shown on the button.
 * @param {string} buttonSvg - Image src shown on the button.
 * @param {string} tooltipText - Text displayed on button hover.
 * @param {func} onClick - Function executed onClick for the button. 
 */
const Button = ({
  className,
  width,
  height,
  fontSize,
  buttonText,
  buttonSvg,
  tooltipText,
  onClick,
}) => {
  return (
    <button
      className={className || "button-predefined-style"}
      style={{ width, height, fontSize }}
      onClick={onClick}
      title={tooltipText}
      alt={tooltipText || buttonText}
    >
      {buttonText}
      {buttonSvg && <img src={buttonSvg} alt="" />}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  buttonText: PropTypes.string,
  buttonSvg: PropTypes.string,
  tooltipText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Button;