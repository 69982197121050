import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Wrapper from "../Layout/Wrapper";
import "./footerPage.css";

/**
 * AGBs component is part of the footer.
 * The component contains textual informations
 * and logo which navigates to homepage.
 * @component
 */
const Terms = () => {
  return (
    <Wrapper>
      <Header />
      <div className="textual-page">
        <h1>Terms of use</h1>
        <p>
          Last updated 22 March 2023
        </p>
        <h2>AGREEMENT TO THE TERMS AND CONDITIONS</h2>
        <p>
          These Terms of Use constitute a legally binding agreement between you, personally or on behalf of a legal entity ("you"), and
          Billionmatic (<b>"Billionmatic", "we", "us" or "our"</b>) regarding your access to and use of the Billionmatic website and any other media form,
          media channel, mobile website or mobile application connected, linked or otherwise associated with it (collectively, the "Website").
          By accessing the Website, you acknowledge that you have read, understood and agree to be bound by these Terms of Use.
          If you do not agree to all of these Terms of Use, you are expressly prohibited from using the Website.
        </p>
        <p>
          Any supplemental terms or documents that may be posted on the Website from time to time are hereby expressly incorporated by reference into these Terms of Use.
          We reserve the right, in our sole discretion, to change or modify these Terms of Use at any time and for any reason.
          We will notify you of any changes by updating the date of the last update to these Terms of Use and you waive the right to be notified separately of any such change.
          Please ensure that you review the applicable terms each time you use our website so that you understand which terms apply.
          Your continued use of the Website after the date of posting of the revised Terms of Use will subject you to them and you will be deemed
          to have become aware of and accepted the changes in the revised Terms of Use.
        </p>
        <p>
          The information provided on the Website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or would subject us to any registration requirement in such jurisdiction or country.
          If you access this Website from other locations, you are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
        </p>
        <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          Unless otherwise stated, the Website is owned by us and all source code, databases, features, software, website designs, audio, video, text,
          photographs and graphics on the Website (collectively, the "Content") and the trademarks, service marks and logos contained therein (the "Marks") are owned
          by or controlled by or licensed to us and are protected by copyright and trademark laws and various other intellectual property rights and unfair
          competition laws, international copyright laws and international conventions.
          The Content and the Marks are provided on the Website "as is" solely for your information and personal use. Except as expressly provided in these Terms of Use,
          no part of the Website and no Content or Marks may be copied, reproduced, summarised, republished, uploaded, posted, publicly displayed, encoded, translated,
          transmitted, distributed, sold, licensed or otherwise exploited for any commercial purpose whatsoever without our express prior written permission.
        </p>
        <p>
          Provided you are authorised to use the Website, you are granted a limited licence to access and use the Website and to download or print a portion of
          the Content to which you have been properly granted access solely for your personal, non-commercial use. We reserve all rights not expressly granted
          to you in the Website, the Content and the Marks.
        </p>
        <h2>USER REPRESENTATIONS</h2>
        <p>
          By using the Website, you represent and warrant that:
          (1) that you have legal capacity and agree to abide by these Terms of Use;
          (2) that you are not a minor in the country in which you reside;
          (3) that you will not access the Website through any automated or non-human means, whether through a bot, script or otherwise;
          (4) that you will not use the Website for any illegal or unauthorised purpose; and
          (5) that your use of the Website will not violate any applicable law or regulation.
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not current or incomplete, we have the right to suspend or terminate your account and refuse any
          and all current or future use of the Website (or any portion thereof).
        </p>
        <h2>REIMBURSEMENT POLICY</h2>
        <p>
          All purchases and sales are final and no refunds will be given.
        </p>
        <h2>PROHIBITED ACTIVITIES</h2>
        <p>
          You must not access or use the Website for any purpose other than that for which we make it available. 
          As a user of the website, you agree to refrain from the following:
          <ol>
            <li>Systematically retrieve data or other content from the Website to create or compile, directly or indirectly, a collection, compilation, database or
                directory without written permission from us.</li>
            <li>Trick, defraud or mislead us and other users, especially in an attempt to obtain sensitive account information such as user passwords.</li>
            <li>circumvent, disable or otherwise interfere with security-related features of the Website, including features that prevent or restrict the use or
                copying of Content or enforce restrictions on the use of the Website and/or the Content contained therein.</li>
            <li>In our opinion, denigrate or otherwise harm us and/or the Website.</li>
            <li>Use any information obtained from the Website to harass or harm any other person.</li>
            <li>misuse our support services or submit false reports of abuse or misconduct.</li>
            <li>Use the Website in a manner inconsistent with applicable laws or regulations.</li>
            <li>Engage in unauthorised framing or linking to the Website.</li>
            <li>uploading or transmitting (or attempting to upload or transmit) any virus, Trojan horse or other material, including the excessive use of capital
                letters and spamming (continuous posting of repetitive text) that interferes with another party's uninterrupted use and enjoyment of the Website or modifies,
                impairs, interrupts, alters or disrupts the use, features, functions, operation or maintenance of the Website.</li>
            <li>an automated use of the system, such as the use of scripts to send comments or messages or the use of data mining or similar tools to collect and extract data.</li>
            <li>remove the reference to copyright or other proprietary rights from the content.</li>
            <li>Attempting to impersonate another user or person or using another user's username.</li>
            <li>Uploading or transmitting (or attempting to upload or transmit) any material that acts as a passive or active data collection or transmission mechanism,
               including but not limited to Clear Graphics Interchange Formats ("gifs"), 1×1 pixels, web bugs, cookies or other similar devices
               (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").</li>
            <li>interfere with, disrupt or unreasonably burden the Website or any network or service connected to the Website.</li>
            <li>Harassing, annoying, intimidating or threatening any of our employees or agents who provide any part of the Website to you.</li>
            <li>Attempts to circumvent website measures designed to prevent or restrict access to the website or parts of the website.</li>
            <li>Copy or adapt the software of the Website, including but not limited to HTML, JavaScript or other code.</li>
            <li>Except as permitted by applicable law, decrypt, decompile, disassemble or reverse engineer any software that is part of the Website.</li>
            <li>Except for standard search engines or internet browsers, you may not use, launch, develop or distribute any automated system, including but not
                limited to spiders, robots, cheat utilities, scrapers or offline readers that access the Website, or use or launch any unauthorised scripts or other software.</li>
            <li>Any unauthorised use of the website, including the collection of user names and/or email addresses of users by electronic or other means for the purpose
                of sending unsolicited emails or creating user accounts by automated means or under false pretences.</li>
          </ol>
        </p>
        <h2>USER-GENERATED CONTRIBUTIONS</h2>
        <p>
          The Website provides users with the ability to submit or post content. We may allow you to create, submit, post, display, transmit, perform, publish,
          distribute or broadcast content and materials to us or on the Website, including but not limited to text, writings, video, audio, photographs, graphics,
          comments, suggestions or personal information or other materials (collectively, "Submissions"). Contributions may be viewed by other users of the Site and
          through third party websites. Therefore, any Contributions you submit may be treated in accordance with the Website's Privacy Policy.
          By making or providing Contributions, you represent and warrant that:
          <ol>
            <li>The creation, distribution, transmission, public display or performance, and the access, downloading or copying of your Submissions do not
                infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret or moral rights, of any third party.</li>
            <li>You are the creator and own or have the necessary licences, rights, consents, releases and permissions to authorise us, the Website and other users of
                the Website to use your Submissions in the manner provided on the Website and in these Terms of Use.</li>
            <li>You have the written consent, release and/or permission of each and every identifiable person in your Submissions to use the name or likeness of
                each and every identifiable person to enable the inclusion and use of your Submissions in any manner contemplated by the Site and these Terms of Use.</li>
            <li>Your posts are not false, inaccurate or misleading.</li>
            <li>Your contributions are not unsolicited or unauthorised advertising, pyramid schemes or spam.</li>
            <li>Your posts are not obscene, lewd, lascivious, filthy, violent, harassing, abusive, defamatory or otherwise objectionable.</li>
            <li>Your posts must not ridicule, mock, denigrate, intimidate or insult anyone.</li>
            <li>Your posts will not be used to harass or threaten others (in the legal sense of these terms) or to promote
                violence against any particular person or group of persons.</li>
            <li>Your contributions do not violate any applicable laws, rules or regulations.</li>
            <li>Your contributions do not violate the data protection or publicity rights of third parties.</li>
            <li>Your posts do not violate any applicable law relating to child pornography or any other provision protecting the health or welfare of minors.</li>
            <li>Your posts will not contain offensive comments based on race, national origin, gender, sexual preference or physical disability.</li>
            <li>Your Submissions do not violate any provision of these Terms of Use or any applicable law or regulation and do not link to any material that violates them.</li>
          </ol>
          Any use of the Website in violation of the foregoing shall constitute a breach of these Terms of Use and may result in, among other things, termination
          or suspension of your rights to use the Website.
        </p>
        <h2>PRODUCTS OR SERVICES</h2>
        <p>
          The data and images of the products on the website may be provided by third parties.
        </p>
        <p>
          We have made every effort to display as accurately as possible the data and images of the products that appear on the website.
          We cannot guarantee that the display of data or images on your computer screen is accurate or up-to-date.
        </p>
        <p>
          We reserve the right, but have no obligation, to limit the sale of our products or services to any person, geographic region or jurisdiction.
          We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of products or services we offer.
        </p>
        <p>
          We do not guarantee that the quality of any products, services, information or other materials purchased or received by you will meet
          your expectations or that any errors in the Service will be corrected.
        </p>
        <h2>LEFT FROM THIRD PARTIES</h2>
        <p>
          Certain content, products and services available through our Service may contain materials from third parties.
        </p>
        <p>
          Third party links on the Website may take you to third party websites that are not affiliated with us. We are not responsible
          for examining or evaluating the content or accuracy of, and do not assume any liability or responsibility for, any third party
          materials or websites or any other third party materials, products or services.
        </p>
        <p>
          We are not liable for any damages related to the purchase or use of goods, services, resources, content or other transactions
          in connection with third party websites. Please read the policies of the third party providers carefully and make sure you
          understand them before making any transaction. Complaints, claims, concerns or questions about third party products should
          be directed to the relevant third party.
        </p>
        <h2>CONTRIBUTION LICENCE</h2>
        <p>
          You and the Website agree that we may access, store, process and use any information and personal data that you provide to us
          in accordance with the terms of the Privacy Policy and your choices (including preferences).
        </p>
        <p>
          By submitting suggestions or other feedback about the Website, you agree that we may use and share such feedback for
          any purpose without compensation to you.
        </p>
        <p>
          We do not claim any ownership rights in your Contributions. You retain full ownership of all your Contributions and
          any intellectual property or other proprietary rights associated with your Contributions. We are not liable for any statements
          or representations made in your Contributions that you have provided in any area of the Website.
          You are solely responsible for your Contributions on the Website and expressly agree to indemnify us against any liability and to refrain
          from taking any legal action against us in respect of your Contributions.
        </p>
        <h2>SITE MANAGEMENT</h2>
        <p>
          We reserve the right, but have no obligation, to:
          (1) monitor the Website for violations of these Terms of Use;
          (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including but not limited to reporting
          such user to law enforcement authorities;
          (3) in our sole discretion and without limitation, deny, restrict access to, limit availability of,
          or disable (to the extent technically feasible) your Submissions or any portion thereof;
          (4) in our sole discretion and without limitation, notice or liability, remove or otherwise disable from the Website any files or
          content that are excessive in size or in any way burden our systems; and (5) otherwise manage the Website in a manner that protects
          our rights and property and facilitates the proper functioning of the Website.
        </p>
        <h2>TERM AND TERMINATION</h2>
        <p>
          These Terms of Use will remain in full force and effect for as long as you use the Website. Without limiting any other provision
          of these Terms of Use, we reserve the right, in our sole discretion and without notice or liability, to deny access to and use of
          the Website (including blocking certain IP addresses) to any person for any reason whatsoever, including, without limitation,
          breach of any representation, warranty or covenant contained in these Terms of Use, or any applicable law or regulation.
          We may terminate their use of or participation in the Site, or delete any content or information they have posted, at any
          time and without warning, in our sole discretion. If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of a
          third party, even if you are acting on behalf of the third party. In addition to terminating or suspending your account,
          we reserve the right to take appropriate legal action, including but not limited to civil, criminal and injunctive remedies.
        </p>
        <h2>CHANGES AND INTERRUPTIONS</h2>
        <p>
          We reserve the right to change, modify or remove the content of the website at any time and without giving reasons at our
          own discretion and without prior notice. However, we are under no obligation to update any information on our website.
          We also reserve the right to change or discontinue all or part of the Website at any time without notice.
          We shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Website.
        </p>
        <p>
          We cannot guarantee that the Website will be available at all times. We may experience hardware, software or other problems,
          or we may need to perform maintenance related to the Website, which may result in interruptions, delays or errors.
          We reserve the right to change, revise, update, suspend, discontinue or otherwise modify the Website at any time and for any
          reason without notice to you. You agree that we will not be liable for any loss, damage or inconvenience caused by your
          inability to access or use the Website during any downtime or suspension of the Website.
          Nothing in these Terms of Use shall be construed as imposing any obligation on us to maintain and support the Website or to provide any corrections,
          updates or publications in connection therewith.
        </p>
        <h2>DISPUTE RESOLUTION</h2>
        <p>
        </p>
        <h2>Binding arbitration</h2>
        <p>
          All disputes arising in connection with these Terms of Use or concerning its validity shall be finally settled in accordance
          with the Rules of Arbitration of the Chamber of Industry and Commerce for Munich and Upper Bavaria (IHK München) to the exclusion of the ordinary courts of law.
        </p>
        <h2>Restrictions</h2>
        <p>
          The parties agree that any arbitration shall be limited to the dispute between the individual parties.
          To the extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding;
          (b) there shall be no right or power to arbitrate a Dispute on the basis of a class action or to use a class action procedure; and
          (c) there shall be no right or power to bring a Dispute as an alleged representative of the general public or other persons.
        </p>
        <h2>Exceptions to arbitration</h2>
        <p>
          The parties agree that the following disputes shall not be subject to the binding arbitration provisions set forth above:
          (a) any dispute involving the enforcement or protection or validity of a party's intellectual property rights;
          (b) any dispute relating to or arising out of allegations of theft, piracy, invasion of privacy or unauthorised use; and
          (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, neither party will elect to arbitrate any dispute
          that falls under the portion of this provision found to be illegal or unenforceable.
        </p>
        <h2>CORRECTIONS</h2>
        <p>
          The information on the website may contain typographical errors, inaccuracies or omissions, including descriptions, prices,
          availability and various other information. We reserve the right to correct any errors, inaccuracies or omissions and to
          change or update the information on the website at any time without prior notice.
        </p>
        <h2>DISCLAIMER</h2>
        <p>
          You agree that your use of the Website and our services is at your own risk. To the fullest extent permitted by law,
          we disclaim all warranties, express or implied, in connection with the Website and its use, including without limitation
          the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
          We make no warranties or representations as to the accuracy or completeness of the content of the Website or the content
          of any websites linked to the Website and we accept no liability or responsibility for
          (1) any errors, mistakes orinaccuracies in the content and materials,
          (2) personal injury or property damage of any kind arising from your access to and use of the Website,
          (3) unauthorised access to or use of our secure servers and/or any personal data and/or financial information stored therein,
          (4) any interruption or cessation of transmission to or from the Website,
          (5) any bugs, viruses, Trojan horses or the like transmitted to or through the Website by third parties, and/or
          (6) any errors or omissions in any content or materials or for any loss or damage of any kind incurred as a
              result of the use of any content posted, transmitted or otherwise made available on the Website.
          We do not warrant, endorse, guarantee or accept responsibility for any products or services advertised or offered by
          any third party through the Website, any hyperlinked website or any website or mobile application displayed in a
          banner or other advertisement and we are not a party to or in any way responsible for monitoring transactions between
          them and any third party providers of products or services. As with the purchase of any product or service through any
          medium or in any environment, they should use their best judgement and exercise caution where appropriate....
        </p>
        <h2>LIMITATIONS OF LIABILITY</h2>
        <p>
          In no event will we or our directors, employees or agents be liable to them or to any third party for any direct,
          indirect, consequential, exemplary, incidental, special or punitive damages, including lost profits, lost revenue,
          lost data or other damages arising out of the use of the Website, even if we have been advised of the possibility
          of such damages. Notwithstanding anything to the contrary in this Agreement, our liability to you for any cause
          whatsoever and regardless of the form of action, shall at all times be limited to the lesser of any amount paid by you to us. 
        </p>
        <h2>DESTRUCTION</h2>
        <p>
          You agree to indemnify and hold us, including any subsidiaries, affiliates and all of our respective officers,
          agents, partners and employees, harmless from any loss, damage, liability, claim or demand, including reasonable
          attorneys' fees and costs, made by any third party due to or arising out of:
          (1) your use of the Site;
          (2) your violation of these Terms of Use;
          (3) your breach of any representations and warranties set forth in these Terms of Use;
          (4) your violation of any rights of another, including but not limited to intellectual property rights; or
          (5) any overtly harmful acts to other users of the Site with whom you are connected through the Site.
          Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any
          matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of any such claim.
          We will use reasonable endeavours to notify you of any such claim, action or proceeding which is the subject of this indemnity as soon as we become aware of it.
        </p>
        <h2>USER DATA</h2>
        <p>
          We store certain data that you submit to the Website in order to administer the performance of the Website and data relating to your use of the Website.
          Although we routinely back up data on a regular basis, you are solely responsible for any data that you submit or that
          relates to any activity that you have undertaken on the Website. You agree that we shall not be liable to you for any loss of or
          damage to such data and you hereby waive any right of action against us arising from such loss or damage to data.
        </p>
        <h2>ELECTRONIC COMMUNICATION, TRANSACTIONS AND SIGNATURES</h2>
        <p>
          Visiting the Website, sending emails to us and completing any online forms constitute electronic communications.
          You agree to receive electronic communications and you agree that all agreements, notices, disclosures and other communications
          that we provide to you electronically, by email and on the Website satisfy any legal requirement that such communications
          be in writing. you hereby consent to the use of electronic signatures, contracts, purchase orders and other records and
          to the electronic delivery of notices, policies and records of transactions initiated or completed by us or through the Website.
          You hereby waive any rights or requirements under any law, rule, regulation, ordinance or other law in any jurisdiction requiring
          an original signature or the delivery or retention of non-electronic records, or to make payments or grant credit by other than electronic means.
        </p>
        <h2>MISCELLANEOUS</h2>
        <p>
          These Terms of Use and any policies or operating rules posted by us on or in relation to the Website constitute the entire
          agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these
          Terms of Use shall not be deemed a waiver of such right or provision. These Terms of Use apply to the fullest extent permitted by law.
          We may assign our rights and obligations, in whole or in part, to any third party at any time.
          We will not be responsible or liable for any loss, damage, delay or inaction caused by any cause beyond our reasonable control.
          If any provision or part of a provision of these Terms of Use is found to be illegal, void or unenforceable, that provision
          or part of the provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability
          of any remaining provisions. No joint venture, partnership, employment, or agency relationship is created between you
          and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use shall not be construed
          against us merely because we have drafted them. You hereby waive any and all defenses you may have based on the electronic
          form of these Terms of Use and the failure of the parties to sign these Terms of Use.
        </p>
        <h2>CONTACT US</h2>
        <p>
          To resolve a complaint regarding the Site, or for more information about your use of the Site,
          please contact us at lion(at)bavariancryptolabs.com .
        </p>
      </div>
      <Footer />
    </Wrapper>
  );
};
export default Terms;
