import PropTypes from "prop-types";

/**
 * Generic input field component that lets the user
 * enter data that is then sent to the parent.
 * 
 * @component
 * @param {string} name - Name of the field displayed on top.
 * @param {string} value - State value set on field.
 * @param {func} onChange - Triggered on input change with new value.
 * @param {string} type - Input type, ex. text or number.
 * @param {string} className - Class of the input element.
 * @param {string} containerClassName - Input container div class. 
 */
const InputField = ({
  name,
  value,
  onChange,
  type,
  className,
  containerClassName,
}) => {
  const onInputChange = (e) => {
    const newValue = e.target.value;

    if (type !== "number") {
      onChange(newValue);
    } else if (type === "number" && newValue) {
      onChange(Math.max(0, Math.min(1000000, newValue)));
    } else {
      onChange("");
    }
  };

  return <div className={containerClassName}>
    <input
      type={type || "text"}
      className={className}
      placeholder={name}
      value={value}
      min={type === "number" ? 0 : undefined}
      max={type === "number" ? 1000000 : undefined}
      onChange={onInputChange}
    />
  </div>
};

InputField.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default InputField;
