import PropTypes from "prop-types";
import { useState } from "react";
import Button from "../Layout/Button";
import InputField from "../Layout/InputField";

/**
 * Shows various editable fields for a particular NFT
 * in order for those field to be updated.
 * Used in the ManageSidebar to update data.
 * 
 * @component
 * @param {object} initialMetadata - Data that is shown first prior to edit.
 * @param {number} sellerFeePercent - 0.x% of the fee applied on sale of the NFT.
 * @param {func} onUpdateMetadata - Triggered on "Apply Changes".
 * @param {func} onSetPrice - Triggered on "Set Price".
 * @param {func} onRemoveFromSale - Triggered on "Remove from sale". 
 */
const ManageMetadata = ({
  initialMetadata,
  sellerFeePercent,
  onUpdateMetadata,
  onSetPrice,
  onRemoveFromSale,
}) => {
  const [name, setName] = useState(initialMetadata.name || "");
  const [url, setURL] = useState(initialMetadata.url || "");
  const [price, setPrice] = useState(initialMetadata.price || 0);

  return <div className="manage-metadata">
    <InputField
      name="Name"
      value={name}
      onChange={setName}
      type="text"
      className="metadata-field"
      containerClassName="metadata-field-container"
    />
    <InputField
      name="URL"
      value={url}
      onChange={setURL}
      type="text"
      className="metadata-field"
      containerClassName="metadata-field-container"
    />
    <Button
      buttonText="Apply changes"
      className="button-filled-style flex-end"
      onClick={() => onUpdateMetadata(initialMetadata.id, { name, url })}
    />

    <div className="metadata-price">
      <InputField
        name="Price"
        value={price}
        onChange={setPrice}
        type="number"
        className="metadata-field"
        containerClassName="metadata-field-container"
      />

      <div className="metadata-price-currency">
        MATIC
      </div>

      {!!price && sellerFeePercent && (
        <div className="manage-sale-fee">
          <div className="seller-price">
            You'll Receive: <span className="currency">{(price * (1 - sellerFeePercent / 100)).toFixed(2)} MATIC</span>
          </div>
          <div className="percentage-fee">
            {sellerFeePercent}% Fee
          </div>
        </div>
      )}
    </div>

    <div className="manage-sale-options">
      <Button
        buttonText="Remove from sale"
        className="button-outlined-style"
        onClick={onRemoveFromSale}
      />
      <Button
        buttonText="Set a new price"
        className="button-filled-style"
        onClick={() => onSetPrice(price)}
      />
    </div>
  </div>
};

ManageMetadata.propTypes = {
  initialMetadata: PropTypes.object.isRequired,
  sellerFeePercent: PropTypes.number.isRequired,
  onUpdateMetadata: PropTypes.func.isRequired,
  onSetPrice: PropTypes.func.isRequired,
  onRemoveFromSale: PropTypes.func.isRequired,
};

export default ManageMetadata;