import PropTypes from "prop-types";
import Button from "./Button";

/**
 * Popover container that contains a list of PopoverButtons.
 * Renders a backdrop that 
 * 
 * @component
 * @param {bool} show - Whether to display the popover or not.
 * @param {bool} setShow - Toggles between showing and not showing the popover.
 * @param {array} actionList - List of PopoverButtons to render.
 */
const PopoverButtonList = ({ show, setShow, actionList }) => {
  if (!show)
    return;

  const buttonList = actionList.map(action => (
    <Button
      key={action.key}
      className={action.class}
      buttonText={action.text}
      onClick={() => {
        setShow(false);
        action.onClick();
      }}
    />
  ));

  return <div className="popover-button-list-container">
    <div className="popover-backdrop" onClick={() => setShow(false)} />
    <div className="popover-button-list">{buttonList}</div>
  </div>
};

PopoverButtonList.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  actionList: PropTypes.array.isRequired,
};

export default PopoverButtonList;