import { useContext } from "react";
import { WalletContext } from "../../context/WalletContext";
import "./buy.css";
import noNft from "../../images/buyNFT.svg";
import OptionalAddress from "../Mint/OptionalAddress";

/**
 * Shows the right section on the buy page.
 * On mobile version, this section open as bottom drawer.
 * Checks whether the nft is selected, and based on it
 * shows the information
 * if nft is selected, on the right side, image, size,
 * price, coordinate and id shows.
 * @component
 */
const NFTInfo = (props) => {
  const {
    buyData,
    nftClicked,
    bottomDrawer,
    openBottomDrawer,
    setShowSuccessModal,
    userNft,
  } = props;
  const { 
    account,
    showWallet, 
    setShowModal, 
    sendBuyTransaction 
  } = useContext(WalletContext);

  const onBuy = (optionalReceiver) => {
    if (!showWallet) {
      setShowModal(true);
    }
    if (!userNft) return;
    const specificId = buyData?.nft?.id;
    const idExist = userNft.filter((item) => item.id === specificId);

    if (showWallet && idExist.length > 0) {
      alert("This is the NFT you already own!");
    }
    if (showWallet && idExist.length <= 0) {
      sendBuyTransaction(
        optionalReceiver || account,
        buyData.transaction,
        setShowSuccessModal
      );
    }
  };

  return (
    <div className="right-section">
      {!nftClicked ? (
        <>
          <button
            className="bottom-drawer"
            onTouchStart={() => openBottomDrawer(!bottomDrawer)}
            onClick={() => openBottomDrawer(!bottomDrawer)}
          >
            <hr />
          </button>

          <img src={noNft} alt="no-nft" className="no-nft-selected" />
          <h1>Select your NFT!</h1>
          <p>Select the NFT you would like to purchase and see the details.</p>
        </>
      ) : (
        <>
          <button
            className="bottom-drawer"
            onTouchStart={() => openBottomDrawer(!bottomDrawer)}
            onClick={() => openBottomDrawer(!bottomDrawer)}
          >
            <hr />
          </button>
          <h2 className="buy-nft-title">You can buy your NFT here</h2>
          <div className="nft-info-wrapper">
            <div>
              <img src={buyData?.imageUrl} alt="logo" width={"100px"} />
            </div>

            <div className="nft-info info-title">
              <p>NFT</p>
              <p>Coordinate</p>
              <p>Size</p>
              <p className="price">Price</p>
            </div>
            <div className="nft-info info-data">
              <p>{buyData?.nft?.id}</p>
              <p>
                {buyData?.nft?.x}/{buyData?.nft?.y}
              </p>
              <p>
                {buyData?.nft?.w}*{buyData?.nft?.h}
              </p>
              <p className="price price-color">{buyData?.nft?.price} MATIC</p>
            </div>
          </div>
          <OptionalAddress
            disabledMessage={userNft?.some(item => item.id === buyData?.nft?.id) ? "You own this NFT!" : null}
            onClick={onBuy}
          />
        </>
      )}
    </div>
  );
};

export default NFTInfo;
